import RenewDialogue from "components/renew-dialogue/RenewDialogue";
import { useNavigate } from "react-router-dom";
import ApiClient from "rest/ApiClient";
import styled from "@emotion/styled";

import MoodRoundedIcon from "@mui/icons-material/MoodRounded";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import LoadingBar from "components/loading-bar/LoadingBar";

import { Circles } from "react-loader-spinner";
import { ConfirmationDialogue } from "components/confirmation-dialogue/ConfirmationDialogue";
import Modal from "react-modal";

const modalStyle = {
  overlay: {
    zIndex: 10,
    background: "rgba(0,0,0,0.25)",
    backdropFilter: "blur(0.8vw)",
    WebkitBackdropFilter: "blur(0.8vw)",
  },
  content: {
    maxWidth: '80%',
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "1em",
    background:
      " linear-gradient(135deg, rgba(212,212,207,1) 0%, rgba(250,250,240,1) 50%, rgba(217,217, 206,1) 100%)",
    boxShadow: "3px 3px 6px rgba(0,0,0,0.2)",
    overflow: "hidden",
	  border: "none"
  },
};

const PopupContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

const ModalContents = ({
  userInfo,
  pollCheckInterval,
  appState,
  uploadingBarRef,
  selfieConfirmed,
  licenceData,
  confirmedLicenceDetails,
  setLicenceData,
  setLicenceImageUri,
  setShowModal,
  error,
  uploadingMessage,
  showModal,
}: any) => {
  const navigate = useNavigate();
  const handleModalContents = () => {
    if (userInfo.currentMemberInfo?.isUserAMember) {
      const eligibleForRenewal =
        userInfo.currentMemberInfo!.eligibleForRenewal ?? false;

      return (
        <RenewDialogue
          expiryDateString={userInfo.currentMemberInfo!.expirationDate ?? ""}
          eligibleForRenewal={eligibleForRenewal}
          confirmTapped={() => {
            if (eligibleForRenewal) {
              navigate(`/${ApiClient.venueKey}/form`, { replace: true });
            } else {
              navigate(`/${ApiClient.venueKey}`, { replace: true });
            }
          }}
        />
      );
    }

    if (!!pollCheckInterval.current) {
      return (
        <PopupContainer
          style={{
            maxWidth: "calc(var(--root-view-width) * 0.5)",
            textAlign: "center",
            padding: "20px 0",
          }}
        >
          <Circles
            height="50"
            width="50"
            color="#2767ff"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <div style={{ padding: "1rem" }}>
            Checking your membership with {appState.theme?.displayName}
          </div>
          <LoadingBar ref={uploadingBarRef} creepFromStart />
        </PopupContainer>
      );
    }

    if (selfieConfirmed) {
      return (
        <PopupContainer style={{ aspectRatio: 1 }}>
          <div style={{ maxWidth: "40vw", textAlign: "center" }}>
            <MoodRoundedIcon
              style={{ fontSize: 60, color: "#0021bf" }}
              fontSize={"inherit"}
            />
            <br />
            Selfie matched!
          </div>
        </PopupContainer>
      );
    }

    if (licenceData) {
      return (
        <PopupContainer style={{ maxWidth: "80vw" }}>
          <ConfirmationDialogue
            info={licenceData}
            confirmTapped={confirmedLicenceDetails}
            declineTapped={() => {
              setLicenceImageUri(undefined);
              setLicenceData(undefined);
              setShowModal(false);
            }}
          />
        </PopupContainer>
      );
    }

    if (error) {
      return (
        <PopupContainer style={{ aspectRatio: 1 }}>
          <FmdBadIcon style={{ fontSize: 60 }} fontSize={"inherit"} />
          <div style={{ maxWidth: "40vw", textAlign: "center" }}>{error}</div>
        </PopupContainer>
      );
    }

    return (
      <PopupContainer style={{ aspectRatio: 1 }}>
        <Circles
          height="50"
          width="50"
          color="#2767ff"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        {uploadingMessage && (
          <>
            <div>{uploadingMessage}</div>
            <LoadingBar ref={uploadingBarRef} />
          </>
        )}
      </PopupContainer>
    );
  };

  return (
    <Modal isOpen={showModal} style={modalStyle} shouldCloseOnOverlayClick={false}>
      {handleModalContents()}
    </Modal>
  );
};

export default ModalContents;
