import React, {useCallback, useEffect, useRef, useState} from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import styled from "@emotion/styled";


const CaptureButton = styled.button`
  appearance: none;
	position: relative;
  width: calc(var(--root-view-width) * 0.2);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.9);
	border: none;
  //border: rgba(255,255,255,0.7) 10px solid;
  //outline: #ffffff54 8px solid;
  //outline-offset: 8px;
	
  align-self: center;
  
  &:active {
    opacity: 0.5;
  }

  &:before {
    content: "";
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    border: 5px solid rgba(255,255,255,0.9);
    border-radius: 50%;
  }
`;

const BackButton = styled.button`
  appearance: none;
  border: none;
  //background: none;
  //padding: 10px;
  background: linear-gradient(90deg, rgba(178,144,68,1) 0%, rgba(199,185,82,1) 60%);
  color: white;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 50%;
  font-size: 1.5em;
  padding: 0.25em 0.45em 0 0.3em;

  &:active {
    opacity: 0.5;
  }
`;

const ConfirmButton = styled.button`
  appearance: none;
  user-select: none;
  //width: calc(100% - 40px);
  width: 60%;
  height: 50px;
  border: none;
  font-family: Montserrat, system-ui;
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  &:active {
    opacity: 0.5;
  }
`;
const RetakeButton = styled.button`
  appearance: none;
  user-select: none;
  //width: calc(100% - 40px);
  width: 60%;
  height: 50px;
  border: none;
  font-family: Montserrat, system-ui;
  font-size: 1.1em;
  font-weight: 500;
  //border-radius: 27px;
  background: none;
  //color: #09005d;
  //color: rgb(36,38, 45);
  color: white;
  //opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
	&:active {
		opacity: 0.5;
	}
`;

type CameraControlsProps = {
	haveImage: boolean,
	onCapture: () => void,
  onBack: () => void,
  onConfirm: () => void,
  onRetake: () => void,
}

const CameraControls = ({haveImage, onCapture, onBack, onConfirm, onRetake}: CameraControlsProps) => {
	const touchRef = useRef<number | null>(null);

	useEffect(() => {
		if (haveImage) {
			setTimeout(() => {
				// console.log('clearing touch block');
				touchRef.current = null;
			}, 50);
		}
	}, [haveImage]);


	// confirm/retake image
	if (haveImage) {
		return (
			<div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', gap: 4}}>
				<ConfirmButton onClick={() => {
					if (touchRef.current) return;
					onConfirm();
				}}>Confirm</ConfirmButton>
				<RetakeButton onClick={() => {
					if (touchRef.current) return;
					onRetake();
				}}>
					<span>Retake</span>
					<ReplayIcon />
				</RetakeButton>
			</div>
		)
	}

	// capture image
	return (
		<>
			<BackButton onClick={onBack}>
				<ArrowBackIosNewRoundedIcon style={{fontSize:'1.5em'}} fontSize={'inherit'} />
			</BackButton>
			{/*<CaptureButton onClick={() => setImage(cameraInterface.current?.captureImage())} />*/}
			<CaptureButton onPointerDown={(e) => {
				touchRef.current = e.pointerId;
				onCapture();
			}} />
			<BackButton style={{visibility: "hidden"}}>
				<ArrowBackIosNewRoundedIcon fontSize={'large'} />
			</BackButton>
		</>
	)
}
export default CameraControls;
