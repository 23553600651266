import React from 'react';
import styled from "@emotion/styled";
import {useMembershipApplication} from "context/ApplicationContext";

// const Logo = styled.img`
// 	height: 70%;
// 	object-fit: contain;
//   padding: 10px 10px 10px 10px; //padding positions
// `;

// const HeaderBar = styled.div`
// 	width: 100%;
// 	aspect-ratio: 6;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
//   overflow: hidden; // need this to force accept aspect ratio
// `;

type RoundedHeaderProps = {
	showShadow?: boolean
}
const RoundedHeader = styled.div<RoundedHeaderProps>`
  //height: 13vh;
	width: 100%;
	aspect-ratio: 4;
	overflow: hidden;
  display: flex;
  justify-content: center;

  //margin-top: -15px;
	margin-top: calc(var(--root-view-width) * -0.05);
  position: relative;
	z-index: 1;
	
  & > svg {
    position: absolute;
    display: block;
    ${props => {
      if (props.showShadow) {
        return `filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 0.6));`;
      }
    }};
  }
  & > svg path {
    //fill: #F4AF20;
  }
  & > img {
    position: absolute;
	  height: 65%;
	  top: 25%;
    aspect-ratio: 1.2;
    object-fit: contain;
    object-position: 50% 75%;
  }
`;


interface HeaderProps {
	style?: React.CSSProperties | undefined
	showShadow?: boolean
}

const HeaderBar = ({ style, showShadow }: HeaderProps) => {
	const { appState } = useMembershipApplication();

	let innerColour = "rgb(39,39,38)";
	let outerColour = "rgb(8,6,8)";
	// if (appState.theme?.headerColor) {
	//
	// }

	return (
		<RoundedHeader showShadow={showShadow}>
			<svg width="100%" height="100%" viewBox="0 0 50 16" preserveAspectRatio="none" fill="url('#header-gradient')" cx="50%" cy="40%" r="70%">
				<defs>
					<radialGradient id="header-gradient">
						<stop offset="10%" stopColor={innerColour} />
						<stop offset="100%" stopColor={outerColour} />
					</radialGradient>
				</defs>
				{/*<path d="M 50 0 L 50 6 C 50 8 49 9 47 9 L 34 9 C 29 9 33 16 28 16 L 22 16 C 16 16 21 9 15 9 L 3 9 C 1 9 0 8 0 6 L 0 0" fill="url('#header-gradient')"/>*/}
				<path d="M 50 0 L 50 4 C 50 7 48 9 45 9 L 35 9 C 29 9 34 16 27 16 L 23 16 C 16 16 21 9 14 9 L 5 9 C 2 9 0 7 0 4 L 0 0"/>
			</svg>
			{/*<img src='../assets/images/logo_hornsby.png'/>*/}
			<img src={appState.theme?.logoImgUrl} alt='logo'/>
			{/*<img src='../assets/images/playerelitelogo_large.png'/>*/}
		</RoundedHeader>
	)

	// return (
	// 	<HeaderBar style={{...style, ...backgroundStyle()}}>
	// 		<Logo src={appState.theme?.logoImgUrl}/>
	// 	</HeaderBar>
	// )
}

export default HeaderBar
