import React, {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from "react";
import CameraPhoto, {FACING_MODES, IMAGE_TYPES} from "jslib-html5-camera-photo";
import styled from "@emotion/styled";

export type FacingMode = 'user' | 'environment';
// export type ImageType = 'png' | 'jpg';

interface Resolution {
    height?: MediaTrackConstraints['height'] | undefined;
    width?: MediaTrackConstraints['width'] | undefined;
}


type VideoStreamProps = {
    flipX?: boolean
}
const VideoStream = styled.video<VideoStreamProps>`
  width: var(--root-view-width);
  //width: 100%;
  //height: 100%;
  pointer-events: none;
  z-index: -1;

  ${props => props.flipX ? 'transform: scaleX(-1);' : ''}
`;

export interface CameraInterface {
    startCamera: (idealFacingMode: FacingMode) => Promise<boolean>,
    stopCamera: () => void,
    captureImage: () => string | undefined
}

interface CameraProps {
    onCapture?: () => void,
    onPermissionStateChange: (newState: PermissionState) => void
}

const Camera = (
        { onCapture, onPermissionStateChange }: CameraProps,
        ref: Ref<CameraInterface>,
    ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [cameraPhoto, setCameraPhoto] = useState<CameraPhoto | null>(null);
    const [flipX, setFlipX] = useState<boolean>(false);
    // const [permissionState, setPermissionState] = useState<PermissionState>('prompt');

    useImperativeHandle(ref, () => ({
        // startCamera: (idealFacingMode: FacingMode, resolution?: Resolution) => startCamera(idealFacingMode, resolution),
        startCamera: (idealFacingMode: FacingMode) => startCameraMaxResolution(idealFacingMode),
        stopCamera,
        captureImage
    }));

    useEffect(() => {
        if (!videoRef.current) return;
        setCameraPhoto(new CameraPhoto(videoRef.current));
        return () => {
            // FIXME: if we press back (go back page to landing) while camera is still active, then re-init camera the resolution is incorrect
            //        - try detect if camera is running on first launch, or even using react router when page changes
            console.log("dismount inside camera ")

            stopCamera()
            setCameraPhoto(null);
            // const x = cameraPhoto?.getInputVideoDeviceInfos()
            // console.log(x);
            // cameraPhoto?.stopCamera()
        };
    }, []);

    // const startCamera = async (idealFacingMode: FacingMode | undefined, idealResolution: Resolution = {} ) => {
    //     /* Resolution:
    //     * { width: 640, height: 480 }
    //     * */
    //
    //     try {
    //         await cameraPhoto?.startCamera(idealFacingMode, idealResolution)
    //         console.log('camera is started !');
    //         await videoRef.current?.play();
    //         return true;
    //     } catch (error) {
    //         console.error('Camera not started!', error);
    //         return false;
    //     }
    // }

    const startCameraMaxResolution = async (idealFacingMode: FacingMode | undefined) => {

        if (!videoRef.current) {
            console.error('NO VIDEO ELEMENT');
            return false;
        }
        if (!cameraPhoto) {
            console.error('CAM LIB NOT LOADED');
            return false;
        }

        const useFrontCamera = idealFacingMode !== undefined && idealFacingMode === FACING_MODES.USER;
        setFlipX(useFrontCamera)

        // Get users permission to fetch devices
        let tempStream;
        try {
            tempStream = await navigator.mediaDevices.getUserMedia({video:true, audio: false}) // `audio: false`
        } catch (error: any) {
            console.error(error);
            // alert(`error: ${error.message}`);
            if (error.message === 'Permission denied' || error.name === 'NotAllowedError') {
                onPermissionStateChange('denied');
            }
            return false;
        }
        onPermissionStateChange('granted');

        // filter devices by orientation
        const cameraLabel = useFrontCamera ? 'front' : 'back'; // todo: need localization?
        const devices = await navigator.mediaDevices
                                        .enumerateDevices()
                                        .then(devices => devices.filter(device => device.kind === 'videoinput'));
        const filteredDevices = devices.filter(device =>
          device.label.toLowerCase().includes(cameraLabel)
          && !device.label.toLowerCase().includes("telephoto")
        );
        // alert(`devices: ${devices.length}, filteredDevices: ${filteredDevices.length}`);
        // alert(`filteredDevices: ${JSON.stringify(filteredDevices)}`);
        // alert(`opening ${cameraLabel} camera`);

        let deviceId;
        if (devices.length === 0) {
            alert('No camera found');
            return false;
        } else if (filteredDevices.length === 0) {
            // default camera for desktop / localised description?
            // todo: handle localized description or default to use facingMode instead of deviceID?
            deviceId = devices[0].deviceId;
        } else {
            deviceId = filteredDevices[filteredDevices.length - 1].deviceId;
        }

        // const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        // alert(`supportedConstraints: ${JSON.stringify(supportedConstraints)}`);

        // close the temp stream
        tempStream.getTracks().forEach(t => t.stop());

        try {
            // NOTE: if we set idealResolution past 720p here, the camera doesnt seem to focus?
            await cameraPhoto.startCamera(deviceId, {width: 1280, height: 720});
            // await cameraPhoto.startCameraMaxResolution(deviceId);
            console.log('camera is started !');
            await videoRef.current?.play();
            // FIXME: camera doesnt refocus when we enter camera then go back out and in again. Not releasing media device?
            // cameraPhoto.stream?.getTracks().forEach(t => t.applyConstraints({focusMode: 'continuous'} as any));
            // alert(`Resolution for camera is ${videoRef.current.videoWidth}x${videoRef.current.videoHeight}`);
            return true;
        } catch (error) {
            console.error('Camera not started!\n', error);
            // alert('Camera not started!' + error);
            return false;
        }
    }

    const captureImage = () => {
        const config = {
            sizeFactor: 1,
            imageType : IMAGE_TYPES.JPG,
            imageCompression : .92,
            // isImageMirror : flipX
        };
        return cameraPhoto?.getDataUri(config);
    }

    const stopCamera = () => {
        cameraPhoto?.stopCamera()
            .then(() => {
                console.log('Camera stopped!');
            })
            .catch((error) => {
                console.log('No camera to stop!:', error);
            });
    }

    return (
        <div>
            <VideoStream
                ref={videoRef}
                autoPlay={false}
                controls={false}
                playsInline
                muted
                flipX={flipX}
                // preload={'auto'}
            />
        </div>
    );
}
export default forwardRef(Camera)

