import styled from "@emotion/styled";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import {useState} from "react";
import Header from "components/header/Header";
import {useMembershipApplication} from "context/ApplicationContext";
import ToggleButton from "components/toggle-button/ToggleButton";
import PhonePreview from "components/phone-preview/PhonePreview";
import GradientButton from "components/gradient-button/GradientButton";
import {LicenceType} from "rest/ApiClient";

const Container = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;
  user-select: none;
	font-family: Montserrat, system-ui;
	
  //padding: 20px 0;
  box-sizing: border-box;
  background: radial-gradient(70% 100% at 90% 100%, rgba(209,208,198, 0.7) 0%, #FF000000 100%),radial-gradient(60% 70% at 0% 100%, rgba(255,255,254,1) 0%, #FF000000 100%),linear-gradient(270deg, rgba(210,210,203, 0.5) 0%, rgba(231,231,224, 0.5) 100%);
`;
const TitleText = styled.div`
  //max-width: 50%;
  margin-bottom: 0.5em;
  display: block;
  font-size: 1.8em;
  font-weight: 800;
  //letter-spacing: 0.05em;
`;
const DetailText = styled.div`
	//max-width: 50%;
  margin-bottom: 0.5rem;
  display: block;
  font-size: 0.9em;
  font-weight: 400;
	line-height: 1.8em;
  //letter-spacing: 0.05em;
`;

const PrimaryButton = styled.button`
  appearance: none;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
  width: 85%;
  margin: auto;
  height: 50px;
  border: none;
  font-family: Montserrat, system-ui;
  font-size: 1.2em;
  font-weight: 600;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  color: white;
  transition: all 0.1s ease-in-out;
  &:active {
    transform: scale(0.98);
    opacity: 0.5;
  }
`;

const SecondaryButton = styled.label`
  //appearance: none;
  border: none;
  padding: 1rem;
  //font-family: system-ui;
  font-size: 1em;
  font-weight: 400;
  background: none;
  color: #2b2bff;
  &:active {
    opacity: 0.5;
  }
  &:focus-visible {
    outline: none;
    box-shadow: none;
    background: none;
  }
`;

const DigitalPrimaryButton = styled.label`
  appearance: none;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
  width: 85%;
  margin: auto;
  height: 50px;
  border: none;
  font-family: Montserrat, system-ui, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
	
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);

  color: white;
  transition: all 0.1s ease-in-out;
  &:active {
    transform: scale(0.98);
    opacity: 0.5;
  }
`;

export const CardShape = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  position: absolute;
  //width: 70%;
  //
  //--c: #4e4e4e; /* color of the border */
  //--b: 5px; /* thickness of the border */
  //--w: 35px; /* width of border */
  //--r: 12px; /* radius */

	//--phone-container-width: calc(var(--root-view-width) * 0.5); // note - calculated in parent
  --card-width: calc(var(--phone-container-width) * 0.7);
  width: var(--card-width);

  --c: #4e4e4e; /* color of the border */
  --b: calc(var(--card-width) * 0.025); /* thickness of the border */
  --w: calc(var(--card-width) * 0.2); /* width of border */
  --r: calc(var(--card-width) * 0.06); /* radius */

  &:before {
    content: "";
    position: absolute;
    inset: calc(-1 * var(--b));
    border: var(--c, red) var(--b) solid;
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b)/calc(100% - 2*var(--w)) 100% repeat-y,
    linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w)) repeat-x,
    linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0);
    -webkit-mask-composite: xor; //destination-out;
    mask-composite: exclude;
  }
`;
const DigitalLicenceMockup = styled.img`
  position: absolute;
  top: 25px;
  width: calc(100% - 14px);
  object-fit: contain;
  //mask-image: linear-gradient(to top, transparent 33%, black 50%)
  mask-image: linear-gradient(to top, transparent 20%, black 50%)
`;

const LicencePreview = ({usePhysicalCard}: {usePhysicalCard: boolean}) => {
	const {appState} = useMembershipApplication()

	return (
		<PhonePreview>
			{usePhysicalCard ? (
				<CardShape>
					<img
						src={`../assets/images/${appState.theme?.venueState.toLowerCase()}_sample_licence.jpg`}
						style={{width: '100%'}}
						alt='licence-sample'
					/>
				</CardShape>
			) : (
				<DigitalLicenceMockup src={`../assets/images/digital-driver-licence.jpg`} />
			)}
		</PhonePreview>
	)
}

type LicencePropsProps = {
	showCamera: () => void,
	selectedImage: (image: File, licenceType: LicenceType) => void
}

export const LicenceInstructions = ({showCamera, selectedImage} : LicencePropsProps) => {
	const [usePhysicalCard, setUsePhysicalCard] = useState(true);

	const selectedGalleryImage = async (e: any) => {
		// alert(e);
		const image  = e.target.files[0];
		// console.log(image);
		selectedImage(image, usePhysicalCard ? "CARD" : "DIGITAL");
	}

	return (
		<Container>
			<Header />
			{/*<Header style={{marginTop: -20}} />*/}
			<LicencePreview usePhysicalCard={usePhysicalCard} />
			{/*<SecondaryButton onClick={() => setUsePhysicalCard(!usePhysicalCard)}>*/}
			{/*	<span>{ usePhysicalCard ? "Use Digital Licence" : "Use Physical Licence" }</span>*/}
			{/*</SecondaryButton>*/}
			{/*<ToggleButton primaryOption="Use Digital Licence" secondaryOption="Use Physical Licence" onChange={(selected) => {*/}
			{/*	setUsePhysicalCard(selected)*/}
			{/*}} />*/}
			<div style={{ width: '100%' }}>
				<TitleText>Driver Licence</TitleText>
				{usePhysicalCard ? (
					<DetailText>Let's start by taking a photo of your licence.<br/>We need this to verify who you are.</DetailText>
				) : (
					<DetailText>You will require a clear screenshot of your<br/>digital drivers licence in your gallery.</DetailText>
				)}
				<div style={{display: "flex", flexDirection: "column", gap: 8, padding: '8px 0'}}>
					{usePhysicalCard ? (
						<>
							<PrimaryButton onClick={showCamera}>
								<CameraAltRoundedIcon />
								<span>Capture Licence</span>
							</PrimaryButton>
							{/*<SecondaryButton htmlFor="upload-button">*/}
							{/*	Upload from gallery instead*/}
							{/*</SecondaryButton>*/}
							<GradientButton title="Use Digital Licence" onClick={() => setUsePhysicalCard(!usePhysicalCard)} />
						</>
					) : (
						<>
							<DigitalPrimaryButton htmlFor="upload-button">
								<AddPhotoAlternateIcon />
								<span>Choose Image</span>
							</DigitalPrimaryButton>
							<GradientButton title="Use Physical Licence" onClick={() => setUsePhysicalCard(!usePhysicalCard)} />
						</>
					)}
				</div>
				<input
					type="file"
					accept="image/*"
					id="upload-button"
					style={{ display: "none" }}
					onChange={selectedGalleryImage}
				/>
				{/*<GradientButton title={ usePhysicalCard ? "Use Digital Licence" : "Use Physical Licence" } onClick={() => setUsePhysicalCard(!usePhysicalCard)} />*/}
			</div>
		</Container>
	)
}
