import styled from "@emotion/styled";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";
import PrivacyTipRoundedIcon from "@mui/icons-material/PrivacyTipRounded";
import React, {useMemo} from "react";
import Header from "components/header/Header";

const OverlayContainer = styled.div`
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const TitleText = styled.h2`
  color: white;
  text-shadow: 0 0 6px #00000069;
	margin-top: 10vw;
	z-index: 1;
`;
const NoticeText = styled.div`
  box-sizing: border-box;
  width: 80%;
  height: 70px;
  border-radius: 17px;
  padding: 15px 20px;
  margin-bottom: calc(30px + 5vw);
  //margin-top: -30px;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(2px) contrast(1) brightness(0.9); //invert(0.2);
  box-shadow: rgba(0,0,0,0.2) 0 0 5px;
  font-weight: 600;
  color: white;
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const CardShape = styled.div`
  position: relative;
  width: 95%;
  aspect-ratio: 1.6;
  //border-radius: calc(var(--root-view-width) * 0.05);
  //border: rgba(255,255,255,0.5) 3px solid;

  --b: 5px; /* thickness of the border */
  --c: white; /* color of the border */
  --w: calc(var(--root-view-width) * 0.15); /* width of border */
	--r: calc(var(--root-view-width) * 0.05); /* radius */

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border: var(--c, red) var(--b) solid;
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b)/calc(100% - 2*var(--w)) 100% repeat-y,
    linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w)) repeat-x,
    linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0);
    -webkit-mask-composite: xor; //destination-out;
    mask-composite: exclude;
  }
`;
const FaceCutout = styled.div`
  --circle-radius: 35vw;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000033;
  mask: radial-gradient(circle farthest-side at center center, transparent var(--circle-radius), #000 calc(var(--circle-radius) + 2px) 100%) 50% 50%/100% 100% no-repeat;
	backdrop-filter: blur(10px)
`;
const SelfieOutlineBlur = styled.div`
	
	--view-height: 75vh;
  --box-size: calc(var(--root-view-width) * 0.75);
	--top: calc((var(--view-height) - var(--box-size)) * 0.417);
	--left: calc((var(--root-view-width) - var(--box-size)) / 2);
	--right: calc(var(--root-view-width) - var(--left));
	--bottom: calc(var(--top) + var(--box-size));
	--bevel: 14px;
	
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000033;
  
	// straight square
  //clip-path: polygon(evenodd, 0 0, 100% 0, 100% 100%, 0 100%, 
	//													  var(--left) var(--bottom), var(--left) var(--top), var(--right) var(--top), var(--right) var(--bottom), 
	//													  var(--left) var(--bottom), 0 100%);
	
	// bevelled
  clip-path: polygon(evenodd, 0 0, 100% 0, 100% 100%, 0 100%,
						  calc(var(--left) + var(--bevel)) var(--bottom), var(--left) calc(var(--bottom) - var(--bevel)),
						  var(--left) calc(var(--top) + var(--bevel)), calc(var(--left) + var(--bevel)) var(--top),
						  calc(var(--right) - var(--bevel)) var(--top), var(--right) calc(var(--top) + var(--bevel)),
						  var(--right) calc(var(--bottom) - var(--bevel)), calc(var(--right) - var(--bevel)) var(--bottom),
						  calc(var(--left) + var(--bevel)) var(--bottom), 0 100%);
  backdrop-filter: blur(10px);
`;

const SelfieOutlineBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  position: absolute;
  width: 75%;
  aspect-ratio: 1;
  //top: 20%;
  --view-height: 75vh;
  //--box-size: 75vw; //72.6vw;
  --box-size: calc(var(--root-view-width) * 0.75);
  top: calc((var(--view-height) - var(--box-size)) * 0.417);

  --b: 5px; /* thickness of the border */
  --c: #ffffff; /* color of the border */
  --w: 25%; /* width of border */
  --r: 12px; /* radius */

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border: var(--c, red) var(--b) solid;
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b)/calc(100% - 2*var(--w)) 100% repeat-y,
    linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w)) repeat-x,
    linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0);
    -webkit-mask-composite: xor; //destination-out;
    mask-composite: exclude;
  }
`;

const SelfieOutlineOval = styled.div`
	width: 70%;
	height: 85%;
	//background: red;

  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='50%' ry='100%' stroke='white' stroke-opacity='0.7' stroke-width='5%' stroke-dasharray='3% 11.9%' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 50%;

  //border: 4px #ffffffaa dashed;
	//border-radius: 50%;

  //outline: 4px dashed #d5fb62;
  //outline-offset: -4px;
  //overflow:hidden;
`;


const CameraOverlay = ({ type, previewingImage }: { type: "LICENCE" | "SELFIE", previewingImage: boolean }) => {

	if (type === "LICENCE") {
		return (
			<OverlayContainer>
				{/*<TitleText>Capture Licence</TitleText>*/}
				<Header />
				<CardShape />
				<NoticeText>
					{ previewingImage ? (
						<>
							<TipsAndUpdatesRoundedIcon />
							<span>Make sure your licence aligns with the white guide box</span>
						</>
					) : (
						<>
							<PrivacyTipRoundedIcon />
							{/*<span>Take a photo of your licence to help us verify your identity</span>*/}
							<span style={{fontSize: '0.78em'}}>We do not hold any digital imagery past the application of your membership as per privacy legislation</span>
						</>
					) }
				</NoticeText>
			</OverlayContainer>
		)
	}

	// SELFIE OVERLAY
	return (
		<OverlayContainer>
			{/*<TitleText>Capture Selfie</TitleText>*/}
			<Header />
			<SelfieOutlineBlur />
			<SelfieOutlineBox>
				<SelfieOutlineOval />
			</SelfieOutlineBox>
			<NoticeText>
				{ previewingImage ? (
					<>
						<TipsAndUpdatesRoundedIcon />
						<span>Make sure your face is aligned within the box</span>
					</>
				) : (
					<>
						<PrivacyTipRoundedIcon />
						<span>Take a selfie to help us verify your identity</span>
					</>
				) }
			</NoticeText>
		</OverlayContainer>
	)
}
export default CameraOverlay;
