import styled from "@emotion/styled";
import {MemberCard, MemberCardProps, PlaceholderCard} from "components/member-card/MemberCard";
import {useMembershipApplication} from "context/ApplicationContext";
import {useEffect, useRef, useState} from "react";
import ApiClient from "rest/ApiClient";
import Header from "components/header/Header";
import {QRCodeSVG} from 'qrcode.react';
import {useQuery} from "util/useQuery";
import useBeforeUnload from "util/useBeforeUnload";


const Page = styled.div`
	height: 100%;
	display: flex;
  flex-direction: column;
  user-select: none;
	//align-items: center;
  //justify-content: center;
  //justify-content: space-between;
  //box-sizing: border-box;
	font-family: Montserrat, system-ui;
  background: linear-gradient(135deg, rgba(207,207,202,1) 0%, rgba(248,247,246,1) 40%, rgba(210,210,198,1) 100%);
`;

const Container = styled.div`
  width: 100%;
	height: 100%;
  //margin: 20px 0;
	//margin-top: 10px;
	display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;
	align-items: center;
  //justify-content: center;
  justify-content: space-evenly;
  //box-sizing: border-box;
`;

const CompletedHeader = styled.div`
  text-align: center;
  background: radial-gradient(rgba(34,33,34,1) 10%, rgba(10,8,11,1) 100%);
  font-family: 'Montserrat',system-ui;
  padding-bottom: calc(var(--root-view-width) * 0.2);
  margin-bottom: calc(var(--root-view-width) * -0.15);
`;

const TitleText = styled.div`
	max-width: 95%;
  //margin-bottom: 0.5rem;
  margin: 0.5em auto;
  display: block;
	font-family: Montserrat, system-ui;
  font-size: 1.6em;
  font-weight: 800;
  //background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  //background: linear-gradient(90deg, rgba(178,144,68,1) 0%, rgba(199,185,82,1) 100%);
  //background: linear-gradient(45deg, rgba(186,177,90,1) 0%, rgba(146,128,71,1) 100%);
  background: linear-gradient(45deg, rgba(186,177,90,1) 0%, rgba(161,142,75,1) 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  //letter-spacing: 0.05em;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: break-spaces;
	line-height: 1.5em;
	//font-size: 2em;
`;

const CompletedMessage = styled.div`
  font-size: 12px;
  line-height: 1.5em;
  width: 70%;
  margin: auto;
  color: white;
`;

const CheckoutButton = styled.button`
  appearance: none;
  margin-top: 10px;
  //margin-right: 10px;
  //align-self: end;
  //background: #005dff;
  background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
	gap: 4px;
	width: 60%;
	height: 44px;
	
  &:active {
    opacity: 0.5;
  }
`;

type ProcessingPageProps = {
	cashRequestGuid?: string,
	// checkoutProductId?: string,
	venueMembershipProductId?: number,
	flowToken?: string
}
export const ProcessingPage = ({cashRequestGuid, venueMembershipProductId, flowToken} : ProcessingPageProps) => {
	const titleString = cashRequestGuid ? "Pending payment" : "Processing";
	const detailString = cashRequestGuid ? "Please see staff at front counter to pay. Once approved you will receive an SMS with your card information." : "Once approved you will receive an sms with your card information.";
	const noteString = cashRequestGuid ? "(don't close this page, it will update\nwhen your membership is ready)" : "(please note this may\ntake a few minutes)";

	const [blockBack, setBlockBack] = useState(true);
	useBeforeUnload(blockBack);

	const checkoutStripe = () => {
		console.log("selected pay with STRIPE");
		if (!venueMembershipProductId || !flowToken) return;

		ApiClient.checkoutStripe(venueMembershipProductId, flowToken).then((response: any) => {
			console.log(response);
			const { data: { success, checkoutUrl}} = response;
			if (success) {
				setBlockBack(false)
				setTimeout(() => {
					window.location.replace(checkoutUrl);
				}, 50)
			}
		});
	}

	return (
		<Page>
			<Header />
			<Container style={{justifyContent: "center"}}>
				<Section>
					<h3 style={{ marginBottom: '0.5rem', fontWeight: 800 }}>{titleString}</h3>
					<p style={{maxWidth: "85%", fontSize: '0.9em', fontWeight: 500}}>{detailString}</p>
					<div style={{
						fontStyle: 'italic',
						fontSize: 12,
						// marginTop: -8,
						color: 'dimgrey',
						maxWidth: '80%',
						lineHeight: '1.5em',
						fontWeight: 500
					}}>{noteString}</div>
				</Section>
				<PlaceholderCard style={{marginTop: 40, marginBottom: 80}} />
				{
					cashRequestGuid && venueMembershipProductId && flowToken && (
					<>
						<small>Otherwise pay with Stripe</small>
						<CheckoutButton onClick={checkoutStripe}>
							<img style={{height: '100%'}} src={`../assets/images/stripe.svg`} />Checkout
						</CheckoutButton>
					</>
				)}
			</Container>
		</Page>
	)
}

export const CompletedPage = () => {
	let query = useQuery();
	// const cashPayment = query.has("cash") && query.get("cash") === 'true';
	const cashRequestGuid = query.get("cashRequestGuid")
	const isRenewal = query.has("productType") && query.get("productType") === 'RENEW_MEMBERSHIP'
	const {appState} = useMembershipApplication();
	const [memberCardProps, setMemberCardProps] = useState<MemberCardProps | undefined>(undefined);
	const [qrCodeString, setQrCodeString] = useState<string | undefined>(undefined)
	const pollCheckInterval = useRef<ReturnType<typeof setInterval> | null>(null);

	// todo: handle the session
	// if (!sessionToken) {
	// 	// todo: should we just redirect to home here?
	// 	return <>bad token</>
	// }

	useEffect(() => {
		console.log("useEffect - completed page");
		pollCheckInterval.current = setInterval(checkMembership, 1000);

		return () => {
			console.log("unmounting - completed page");
			clearPoll();
		}
	}, []);

	const clearPoll = () => {
		if (!pollCheckInterval.current) return;
		clearInterval(pollCheckInterval.current);
		pollCheckInterval.current = null;
	}

	const checkMembership = async () => {
		console.log("checking membership");
		const nextFlowGuid = cashRequestGuid ?? query.get("flow") ?? appState.nextFlowGuid;
		const flowToken = query.get("token") ?? appState.flowToken;
		if (!nextFlowGuid || !flowToken) return;
		console.log(`flowToken: ${flowToken}, nextFlowGuid: ${nextFlowGuid}`)

		// const success = Math.random() * 10 < 1
		// if (!success) {
		// 	setTimeout(() => checkMembership(), 1000);
		// 	return;
		// }
		// setMemberCardProps({
		// 	backgroundImage: appState.theme?.cardBackgroundImgUrl ?? "",
		// 	memberPhoto: `../assets/images/harold.png`,
		// 	memberName: "Christopher John MALONEY",
		// 	memberNumber: '00012345',
		// 	expiryDate: new Date(1700805251205)
		// });
		// setQrCodeString('123');
		// clearPoll();
		// return;

		await ApiClient.getMembership(nextFlowGuid, flowToken).then((response: any) => {
			console.log(response);
			/*
			{
		    success: true,
		    body: {
	        userId: null,
	        fullName: "Christopher John MALONEY",
	        badgeNumber: null,
	        selfieImgUrl: "https://hydra-temp-dev.s3.ap-southeast-2.amazonaws.com/259810_1669245879345.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221124T000459Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=AKIAIYBUBWBGTVLORPYQ%2F20221124%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Signature=ba3d4c73a3d7e5b0df86d4299607e3919cafd31d2710b499a17bc9a28b26893c",
	        membershipExpiryTime: 1700805251205,
	        cardQrCode: null
		    }
			}
			*/
			const { data: { success, body }} = response;
			if (!success) return;

			// stop polling
			clearPoll();

			// set the data
			setQrCodeString(body.cardQrCode);
			// body.userId

			const { membershipExpiryTime: expiryDate } = body;
			setMemberCardProps({
				backgroundImage: appState.theme?.cardBackgroundImgUrl ?? "",
				memberPhoto: body.selfieImgUrl,
				memberName: body.fullName,
				memberNumber: body.badgeNumber,
				expiryDate: isNaN(expiryDate) ? expiryDate : new Date(parseInt(expiryDate)).toLocaleDateString('en-AU')
			});
		});
	}

	if (!memberCardProps) {
		// awaiting final call results
		// const nextFlowGuid = query.get("flow");
		const flowToken = query.get("token");
		const venueMembershipProductId = parseInt(query.get("selectedId") ?? "");

		if (cashRequestGuid) {
			return <ProcessingPage venueMembershipProductId={venueMembershipProductId} flowToken={flowToken ?? ''} cashRequestGuid={cashRequestGuid}/>
		}
		return <ProcessingPage/>
	}

	return (
		<Page>

			<CompletedHeader>
				<Header showShadow />
				{ isRenewal ? (
					<>
						<TitleText><small>Membership renewed with<br/>{appState.theme?.displayName}</small></TitleText>
						<CompletedMessage>Thank you for renewing your membership with {appState.theme?.displayName}. Your application has finished processing and your membership card is ready. </CompletedMessage>
					</>
				) : (
					<>
						<TitleText>Welcome to<br/>{appState.theme?.displayName}</TitleText>
						<CompletedMessage>Thank you for signing up with {appState.theme?.displayName}. <br/> Your application has finished processing and your membership card is ready. </CompletedMessage>
					</>
				)}

			</CompletedHeader>
			<Container style={{borderRadius: "calc(var(--root-view-width) * 0.15) 0 0 0", background: 'linear-gradient(135deg, rgba(207,207,202,1) 0%, rgba(248,247,246,1) 40%, rgba(210,210,198,1) 100%)'}}>
				<MemberCard {...memberCardProps} />

				<div style={{ width: "85%", display: 'flex', justifyContent: "center" }}>
					<button style={{width: '40%', appearance: 'none', background: 'none', border: 'none'}} onClick={() => {
						// window.open('https://hydra-api-dev.playerelite-dev.com/api/v1/card/apple','_blank')

						const nextFlowGuid = cashRequestGuid ?? query.get("flow") ?? appState.nextFlowGuid;
						const flowToken = query.get("token") ?? appState.flowToken;
						if (!nextFlowGuid || !flowToken) return;

						const url = `${ApiClient.apiBaseUrl}/v1/venue/${ApiClient.venueId}/card/apple?nextFlowGuid=${nextFlowGuid}&flowToken=${flowToken}`;
						window.open(url,'_blank')



						// ApiClient.getAppleWalletUrl(nextFlowGuid, flowToken)
						// 	.then((response: any) => {
						// 		console.log('response', response);
						//
						// 		const reader = response.data.getReader();
						//
						//
						// 		// window.open(response.data,'_blank')
						// 		const passFile = new File(response.data, "my-file-name.pkpass", { type: "application/vnd.apple.pkpass" });
						// 		// return new Promise((resolve, reject) => {
						// 		// 	let fr = new FileReader();
						// 		// 	fr.onload = x=> resolve(fr.result);
						// 		// 	fr.readAsArrayBuffer(passFile)
						// 		// })
						// 		let fr = new FileReader();
						// 		// fr.onload = x=> resolve(fr.result);
						// 		fr.readAsDataURL(passFile)
						// 	})
						// 	.catch((error) => {});
					}}>
						<img style={{width: '100%'}} src={'../../assets/images/add-to-apple-wallet-logo.png'}/>
					</button>
					<button style={{width: '40%', appearance: 'none', background: 'none', border: 'none'}} onClick={ async () => {
						// window.open('https://hydra-api-dev.playerelite-dev.com/api/v1/card/google-wallet','_blank')
						const nextFlowGuid = cashRequestGuid ?? query.get("flow") ?? appState.nextFlowGuid;
						const flowToken = query.get("token") ?? appState.flowToken;
						if (!nextFlowGuid || !flowToken) return;

						ApiClient.getGoogleWalletUrl(nextFlowGuid, flowToken)
							.then((response: any) => {
								// console.log('response', response);
								const { cardUrl } = response.data;
								if (!cardUrl) return;
								// note: band-aid timeout fix for not opening url on ios
								setTimeout(() => window.open(cardUrl,'_blank'));
							})
							.catch((error) => {});
					}}>
						{/*<img style={{width: '100%'}} src={'https://codelabs.developers.google.com/static/add-to-wallet-android/img/add-wallet-btn-large.png'}/>*/}
						<img style={{width: '100%'}} src={'../assets/images/google-wallet-condensed.png'}/>
					</button>
					{/*<button>print card at kiosk</button>*/}
				</div>

				{/* todo: touch qr image to expand larger - needed for small phones? or just enable pinch to zoom? */}
				<div style={{width: '35%', aspectRatio: 1}}>
					{qrCodeString && (
						<QRCodeSVG value={qrCodeString} size={512} style={{width: '100%', height: '100%'}} bgColor='#ffffff00' />
					)}
				</div>
				<span style={{marginTop: 5, fontSize: '0.7em', fontWeight: 500}}>Scan this QR code at the kiosk<br/>to print your new card</span>

			</Container>
		</Page>
	)
}
