import { BadgeOutlined, PublishedWithChanges } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useMemo } from "react";

type RenewDialogueProps = {
  expiryDateString: string;
  eligibleForRenewal: boolean;
  confirmTapped: () => void;
};

const redPrompt = { backgroundColor: "#dc3545", hoverColor: "#e63946" };
const greenPrompt = { backgroundColor: "#198754", hoverColor: "#206646" };
const yellowPrompt = { backgroundColor: "#ffc107", hoverColor: "#ffca2c" };

const RenewDialogue = ({
  expiryDateString,
  eligibleForRenewal,
  confirmTapped,
}: RenewDialogueProps) => {
  const todayDate = new Date();
  let [d, m, y] = expiryDateString.split(/\D/);
  const expiryDate = new Date(parseInt(y), parseInt(m) - 1, parseInt(d));
  const isExpired = expiryDate < todayDate;
  const isExpiringToday = expiryDate === todayDate;
  // Calculate the grace period (30 days after expiryDate)
  const thirtyDaysAfterExpiry = new Date(expiryDate);
  thirtyDaysAfterExpiry.setDate(expiryDate.getDate() + 30);
  const inGracePeriod =
    todayDate <= thirtyDaysAfterExpiry && todayDate >= expiryDate;

  const getPromptStyle = () => {
    if (!isExpired && eligibleForRenewal) {
      return greenPrompt;
    }

    if (!isExpired && !eligibleForRenewal) {
      return yellowPrompt;
    }

    if (isExpired || isExpiringToday) {
      return redPrompt;
    }

    return greenPrompt;
  };

  const promptStyle = getPromptStyle();

  const detailText = useMemo(() => {
    if (!eligibleForRenewal) {
      return `You have an active membership\nthat will expire on ${expiryDateString}.\n\nYou are not currently eligible for renewal.`;
    }

    if (isExpiringToday) {
      return `You have an active membership\nthat expired today.`;
    }

    if (inGracePeriod) {
      return `You have an active membership\nthat expired on ${expiryDateString}.\n\nYou are currently in the grace period.`;
    }

    if (isExpired) {
      return `You have an active membership\nthat expired on ${expiryDateString}.\n\nA renewal will issue a new badge number.`;
    }

    return `You have an active membership\nthat will expire on ${expiryDateString}.`;
  }, [eligibleForRenewal, isExpiringToday, inGracePeriod, isExpired, expiryDateString]);

  return (
    <>
      <Box
        sx={{
          background: promptStyle.backgroundColor,
          width: "calc(100% + 40px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "5rem",
          marginTop: "-20px",
          marginLeft: "-20px"
        }}
      >
        <BadgeOutlined fontSize="large" sx={{ color: "white" }} />
      </Box>
      <Box sx={{ textAlign: "center", p: 1 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBlockStart: '0.6em'}}>
          {isExpired ? "Expired Membership" : "Active Member"}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {detailText}
        </Typography>
        <Button
          sx={{
            mt: 2,
            padding: '8px 40px',
            borderRadius: 10,
            background:
              "linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%)",
          }}
          variant="contained"
          onClick={confirmTapped}
        >
          {eligibleForRenewal ? "Renew Now" : "Back to homepage"}
        </Button>
      </Box>
    </>
  );
};

export default RenewDialogue;
