import { useCallback } from 'react';
import { useBeforeUnload as _useBeforeUnload, unstable_usePrompt as usePrompt } from 'react-router-dom';

export default function useBeforeUnload(doBlock?: boolean) {
	_useBeforeUnload(
		useCallback(e => {
			console.log(e)
			if (doBlock) {
				e.preventDefault();
				return e.returnValue = '';
			}
		}, [doBlock])
	);

	usePrompt({
		when: doBlock ?? false,
		message: 'Restart application? (you will be redirected to the start page)',
	});
}
