import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError
} from 'react-router-dom';
import ApiClient from "./rest/ApiClient";
import {MembershipApplicationProvider} from "context/ApplicationContext";
import {LandingPage} from "pages/landing/LandingPage";
import {CaptureLicencePage} from "pages/camera/CaptureLicencePage";
import {SignUpPage} from "pages/signup/SignUpFormPage";
import {CompletedPage} from "pages/final/CompletedPage";
import {RenewMembershipPage} from "pages/renew/RenewMembershipPage";
import {FailedPaymentPage} from "pages/final/FailedPaymentPage";
import {RestoreSessionPage} from "pages/restore/RestoreSessionPage";
import {CardDownloadPage} from "pages/final/CardDownloadPage";

// export const apiBaseUrl =
//     process.env.NODE_ENV === 'production'
//         ? 'https://hydra-api-prod.playerelite-dev.com/api'
//         : 'http://192.168.0.77:5000/api';
// export const apiBaseUrl = 'https://hydra-api-dev.playerelite-dev.com/api'
export const apiBaseUrl = 'https://hydra-api-prod.playerelite-dev.com/api'

ApiClient.init(apiBaseUrl);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const key = "AIzaSyBirvHRC_ovnM0E6bwn0JB9j592n8R1180";
// <script src={`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`}/>

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <div>
      <h1>Error</h1>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    // loader: rootLoader,
    children: [
      {
        path: ":venueKey",
        element: <LandingPage/>,
        // loader: rootLoader,
        // children: [
        // 	{
        // 		path: "licence",
        // 		element: <CaptureLicencePage/>,
        // 	},
        // 	{
        // 		path: "form",
        // 		element: <SignUpPage/>,
        // 	},
        // 	{
        // 		path: "complete",
        // 		element: <CompletedPage/>,
        // 	},
        // 	{
        // 		path: "renew",
        // 		element: <RenewMembershipPage/>,
        // 	},
        // 	{
        // 		path: "failed",
        // 		element: <FailedPaymentPage/>,
        // 	},
        //   // "${config.webUrlBase}/${hydraVenueConfig.venueKey}/$token"
        //   {
        //     path: ":token",
        //     element: <RestoreSessionPage/>,
        //     // loader: teamLoader,
        //   },
        // ],
      },
	    {
		    path: ":venueKey/licence",
		    element: <CaptureLicencePage/>,
	    },
	    {
		    path: ":venueKey/form",
		    element: <SignUpPage/>,
	    },
	    {
		    path: ":venueKey/complete",
		    element: <CompletedPage/>,
	    },
	    {
		    path: ":venueKey/renew",
		    element: <RenewMembershipPage/>,
	    },
	    {
		    path: ":venueKey/failed",
		    element: <FailedPaymentPage/>,
	    },
	    // "${config.webUrlBase}/${hydraVenueConfig.venueKey}/$token"
	    {
		    path: ":venueKey/:token",
		    element: <RestoreSessionPage/>,
		    // loader: teamLoader,
	    },
	    {
		    path: ":venueKey/card/:token",
		    element: <CardDownloadPage/>,
		    // loader: teamLoader,
	    },
    ],
  },
], {
  // basename: '/pe',
  // future?: FutureConfig, // specify future flags here
  // hydrationData?: HydrationState, // only for SSR?
  // window?: Window,
})

root.render(
  <React.StrictMode>
    <MembershipApplicationProvider>
      <RouterProvider router={router} />
    </MembershipApplicationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
