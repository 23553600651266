// export const isBrowser = window?.document !== undefined;
// export const isJsDom = navigator?.userAgent?.includes('jsdom') === true;

const platform = navigator?.userAgentData?.platform;

// export const isMacOs = platform === 'macOS'
//     || navigator?.platform === 'MacIntel' // Even on Apple silicon Macs.
//     || navigator?.userAgent?.includes(' Mac ') === true
//     || process?.platform === 'darwin';
//
// export const isWindows = platform === 'Windows'
//     || navigator?.platform === 'Win32'
//     || process?.platform === 'win32';
//
// export const isLinux = platform === 'Linux'
//     || navigator?.platform?.startsWith('Linux') === true
//     || navigator?.userAgent?.includes(' Linux ') === true
//     || process?.platform === 'linux';

export const isIos = platform === 'iOS'
    || (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1)
    || /iPad|iPhone|iPod/.test(navigator?.platform);

export const isAndroid = platform === 'Android'
    || navigator?.platform === 'Android'
    || navigator?.userAgent?.includes(' Android ') === true
    || window.process?.platform === 'android';
