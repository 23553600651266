const Base64toBlob = async (image: string, prefix: string) : Promise<{imageName: string, imageBlob: Blob}> => {
	return new Promise(async (resolve, reject) => {
		try {
			const fileType = image.substring(image.indexOf('/') + 1, image.indexOf(';base64'))
			const imageName = `${prefix}_${Date.now()}_${Math.floor(Math.random()*1000)}.${fileType}`; //image.name
			const imageBlob = await fetch(image).then(res => res.blob());
			resolve({imageName, imageBlob});
		} catch (e) {
			reject(e)
		}
	});
}
export default Base64toBlob;
