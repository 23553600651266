import { useQuery } from "util/useQuery";
import { useEffect, useRef, useState } from "react";
import Header from "components/header/Header";
import styled from "@emotion/styled";
import ApiClient from "rest/ApiClient";
import {
  MembershipProduct,
  useMembershipApplication,
} from "context/ApplicationContext";
import { MembershipButton } from "pages/signup/FormSegements.styled";
import { useNavigate } from "react-router-dom";
import useBeforeUnload from "util/useBeforeUnload";
import { animateScroll, getScrollMidY } from "util/scrollHelper";

const Page = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  //align-items: center;
  //justify-content: center;
  //justify-content: space-between;
  //box-sizing: border-box;
  font-family: Montserrat, system-ui, sans-serif;
  background: linear-gradient(
    135deg,
    rgba(207, 207, 202, 1) 0%,
    rgba(248, 247, 246, 1) 40%,
    rgba(210, 210, 198, 1) 100%
  );
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  //padding-bottom: 15vh;
  box-sizing: border-box;
  //margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;
  align-items: center;
  //justify-content: center;
  justify-content: center;
  //box-sizing: border-box;
`;

const PaymentSummary = styled.div`
  //border-top: 2px dashed lightgray;
  ////border-radius: 16px;
  width: 100%;
  margin-top: 2em;
`;

const PaymentSummaryText = styled.div`
  text-align: left;
  width: 70%;
  margin: auto;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  gap: 8px;
`;

const SelectMembershipContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 8px;
`;

const Paragraph = styled.div`
  font-size: 14px;
  max-width: 80%;
  font-weight: 500;
  color: #444;
  margin: auto;
`;

const CheckoutButton = styled.button`
  appearance: none;
  margin-top: 10px;
  //margin-right: 10px;
  //align-self: end;
  //background: #005dff;
  background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 0.9em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 80%;
  height: 50px;
  font-family: Montserrat, system-ui, sans-serif;
  &:active {
    opacity: 0.5;
  }
`;
// const SecondaryCheckoutButton = styled(CheckoutButton)`
//   background: none;
//   border: 2px solid #1e34f5;
//   color: #1e34f5;
// `;

type ToggleButtonProps = {
  selected?: boolean;
};
const ChangeMembershipButton = styled.button<ToggleButtonProps>`
  appearance: none;
  //border: none;
  font-family: Montserrat, system-ui, sans-serif;
  font-weight: 600;
  height: 40px;
  border-radius: 20px;
  margin-top: 1em;

  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  //background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);

  ${(props) =>
    props.selected
      ? `
    background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
	  border: none;
	  color: white;
    font-size: 1em;
    width: 95%;
  `
      : `
    background: none;
	  border: 2px solid #1e34f5;
	  color: #1e34f5;
    font-size: 0.9em;
    width: 75%;
  `}

  &:active {
    opacity: 0.5;
  }
  &:focus-visible {
    outline: none;
    box-shadow: none;
    background: none;
  }
`;

export const FailedPaymentPage = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const isRenewal =
    query.has("productType") && query.get("productType") === "RENEW_MEMBERSHIP";
  const { appState, setAppState } = useMembershipApplication();
  const [selectedMembership, setSelectedMembership] = useState<
    MembershipProduct | undefined
  >(undefined);
  const [showMembershipOptions, setShowMembershipOptions] = useState(false);
  const memberProductsContainerRef = useRef<HTMLDivElement | null>(null);
  const membershipProducts = isRenewal
    ? appState.theme?.renewalProducts
    : appState.theme?.newProducts;
  // const membershipProducts = [...appState.theme!.renewalProducts, ...appState.theme!.newProducts];

  // todo: fixme: this doesnt seem to be working on this page??
  //      - goes back to stripe still, maybe because the previous page wasn't handled by the router?
  const [blockBack, setBlockBack] = useState(true);
  useBeforeUnload(blockBack);

  useEffect(() => {
    // console.log("useEffect - failed payment page")
    const nextFlowGuid = query.get("flow");
    const flowToken = query.get("token");
    if (!nextFlowGuid || !flowToken) return;

    // console.log(`flowToken: ${flowToken}, nextFlowGuid: ${nextFlowGuid}`)
    const selectedId = parseInt(query.get("selectedId") ?? "");
    setAppState({
      ...appState,
      nextFlowGuid,
      flowToken,
      venueMembershipProductId: selectedId,
    });

    if (!!selectedId) {
      setSelectedMembership(
        membershipProducts?.find(
          (p) => p.venueMembershipProductId === selectedId
        )
      );
    } else {
      setShowMembershipOptions(true);
    }

    // return () => {
    // 	console.log("unmounting - failed payment page")
    // }
  }, []);

  const checkoutCash = () => {
    if (!selectedMembership) return;
    console.log("selected pay with cash");
    const nextFlowGuid = query.get("flow");
    const flowToken = query.get("token");
    if (!nextFlowGuid || !flowToken) return;

    ApiClient.checkoutCash(
      selectedMembership.venueMembershipProductId,
      flowToken
    ).then((response: any) => {
      console.log(response);
      const {
        data: { success, requestGuid },
      } = response;
      if (success) {
        setBlockBack(false);
        navigate(
          `/${ApiClient.venueKey}/complete?cashRequestGuid=${requestGuid}&selectedId=${selectedMembership.venueMembershipProductId}&flow=${nextFlowGuid}&token=${flowToken}`,
          { replace: true }
        );
      }
    });
  };

  const checkoutStripe = () => {
    if (!selectedMembership) return;
    console.log("selected pay with STRIPE");
    const nextFlowGuid = query.get("flow");
    const flowToken = query.get("token");
    if (!nextFlowGuid || !flowToken) return;

    ApiClient.checkoutStripe(
      selectedMembership.venueMembershipProductId,
      flowToken
    ).then((response: any) => {
      console.log(response);
      const {
        data: { success, checkoutUrl },
      } = response;
      if (success) {
        setBlockBack(false);
        window.location.replace(checkoutUrl);
      }
    });
  };

  if (showMembershipOptions) {
    return (
      <Page>
        <Header />
        <Container>
          <SelectMembershipContainer>
            <h2>Select Membership</h2>
            <div
              ref={memberProductsContainerRef}
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "calc(var(--root-view-width) * 0.04)",
                fontSize: "min(calc(var(--root-view-width) * 0.04), 20px)",
                userSelect: "none",
                padding: "6px 20px", // need for shadow
                boxSizing: "border-box",
                maxHeight: "calc(100vh - var(--root-view-width) * 0.8)", // TODO: FIX THIS TO FILL FREE SPACE PROPERLY
                overflowY: "auto",
              }}
            >
              {membershipProducts?.map((membership, index) => {
                return (
                  <MembershipButton
                    key={index}
                    selected={membership === selectedMembership}
                    onClick={(e) => {
                      setSelectedMembership(membership);
                      setAppState({
                        ...appState,
                        venueMembershipProductId:
                          membership.venueMembershipProductId,
                      });

                      // scroll to keep selection in view
                      if (!memberProductsContainerRef.current) return;
                      if (
                        memberProductsContainerRef.current.scrollHeight >
                        memberProductsContainerRef.current.clientHeight
                      ) {
                        const scrollToY = getScrollMidY(
                          e.currentTarget,
                          memberProductsContainerRef.current
                        );
                        // scrollViewRef.current.scrollTop = scrollToY;
                        animateScroll(
                          memberProductsContainerRef.current,
                          scrollToY,
                          300
                        );
                      }
                    }}
                  >
                    <div>
                      <h3 style={{ margin: 0 }}>{membership.displayName}</h3>
                      <div
                        style={{ height: 2, borderRadius: 1, width: "22%" }}
                      />
                      <h3 style={{ margin: 0 }}>{membership.displayPrice}</h3>
                    </div>
                    <div>
                      <small>{membership.description}</small>
                      <strong>Total Cost: {membership.displayPrice}</strong>
                    </div>
                  </MembershipButton>
                );
              })}
            </div>
            <ChangeMembershipButton
              onClick={() => setShowMembershipOptions(!showMembershipOptions)}
              selected={showMembershipOptions}
            >
              {showMembershipOptions ? "Confirm" : "Change membership duration"}
            </ChangeMembershipButton>
          </SelectMembershipContainer>
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      <Header />
      <Container>
        <Section>
          <div>
            <h2>Payment Failed</h2>
            <Paragraph>
              To finalise your membership, proceed using one of the following
              methods:{" "}
            </Paragraph>
          </div>
          {!appState.theme?.removeCashPayment && (
            <CheckoutButton onClick={checkoutCash}>
              Pay with cash
            </CheckoutButton>
          )}
          <CheckoutButton onClick={checkoutStripe}>
            <img
              style={{ height: "100%" }}
              src={`../assets/images/stripe.svg`}
            />
            Checkout
          </CheckoutButton>
        </Section>
        <div style={{ borderTop: "2px dashed lightgray", width: "85%" }} />
        <Section>
          <PaymentSummary>
            <PaymentSummaryText>
              <div style={{ fontSize: 20, fontWeight: 600, marginBottom: 8 }}>
                Summary:
              </div>
              {selectedMembership ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    lineHeight: 2.2,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.9em",
                      fontWeight: 500,
                      color: "#222",
                    }}
                  >
                    - {selectedMembership.description}
                  </div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "start",
                      fontWeight: 600,
                    }}
                  >
                    Total Cost: {selectedMembership.displayPrice}
                  </div>
                </div>
              ) : (
                <span style={{ fontSize: 16, fontWeight: 400 }}>
                  No membership selected
                </span>
              )}
            </PaymentSummaryText>
            <ChangeMembershipButton
              onClick={() => setShowMembershipOptions(!showMembershipOptions)}
              selected={showMembershipOptions}
            >
              {showMembershipOptions ? "Confirm" : "Change membership duration"}
            </ChangeMembershipButton>
          </PaymentSummary>
        </Section>
      </Container>
    </Page>
  );
};
