import styled from "@emotion/styled";
import {useEffect, useRef, useState} from "react";


const TermsText = styled.div`
	//max-height: 290px;
  max-height: calc(100vh - var(--root-view-width) * 1.11); // TODO: FIX THIS TO FILL FREE SPACE PROPERLY
  //max-height: calc(100% - 84px);
	box-sizing: border-box;
  overflow: scroll;
  font-size: 0.8em;
  font-weight: 500;
  color: #494949;
  text-align: justify;
  font-style: italic;
  display: block;
  padding: 25px 25px 0 25px;
  //mask-image: linear-gradient(to bottom, transparent 0%, black calc(50px), black calc(100% - 50px), transparent 100%);
  & h4 {
    margin-block-start: 0;
    margin-block-end: 0;
  }
	& p {
     margin-block-start: 0.5em;
   }
	& ol {
    padding-inline-start: 0;
	}
	& li {
		margin-block-start: 0.5em;
	}
`;

const ConfirmButton = styled.button`
  appearance: none;
  width: calc(100% - 40px);
  margin: 20px;
  height: 44px;
  border: none;
  font-family: 'Montserrat', system-ui;
  font-size: 1em;
  font-weight: 600;
  border-radius: 22px;
  background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  filter: grayscale(0%);
	&:active:enabled {
		opacity: 0.5;
		transform: scale(0.98);
	}
	&:disabled {
		opacity: 0.5;
		filter: grayscale(100%);
	}
  transition: 0.2s ease-in-out;
`;

type TermsConditionsPanelProps = {
	tosRichHtml: string,
	onAcceptTerms: () => void,
}
const TermsConditionsPanel = ({tosRichHtml, onAcceptTerms} : TermsConditionsPanelProps) => {
	const [haveReadTerms, setHaveReadTerms] = useState(false);
	const termsTextRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		handleScroll();
	}, []);

	const handleScroll = () => {
		if (termsTextRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = termsTextRef.current;

			// fade edges if more content to show
			const maxFadeHeight = 30;
			const topFadeHeight = Math.min(scrollTop, maxFadeHeight);
			const bottomFadeHeight = Math.min(scrollHeight - (scrollTop + clientHeight), maxFadeHeight);
			const mask = `linear-gradient(to bottom, transparent 0%, black calc(${topFadeHeight}px), black calc(100% - ${bottomFadeHeight}px), transparent 100%)`
			termsTextRef.current.style.maskImage = mask;
			termsTextRef.current.style.webkitMaskImage = mask;

			// mark ad read if scrolled to bottom
			if (scrollTop + clientHeight >= scrollHeight - 30) {
        setHaveReadTerms(true);
      }
		}
	};

	return (
		<>
      <TermsText ref={termsTextRef} dangerouslySetInnerHTML={{__html: tosRichHtml}} onScroll={handleScroll} />
			<ConfirmButton disabled={!haveReadTerms} onClick={onAcceptTerms}>Accept</ConfirmButton>
    </>
	)
}
export default TermsConditionsPanel
