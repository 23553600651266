import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { forwardRef, useEffect, useState } from "react";
import { InputField } from "pages/signup/FormSegements.styled";
import styled from "@emotion/styled";
import {Suggestion} from 'use-places-autocomplete'

const SuggestedPlacesList = styled.ul`
  padding: 0;
  margin: 8px 0 0 0;
  list-style-type: none;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d9d9ff;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  user-select: none;

  & li {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 12px;
  }
`;

export type SelectedAddressType = {
  full: string;
  split: string[];
  isValidAddress: boolean;
  hasPostCode: boolean;
};

type PlaceAutocompleteProps = {
  placeholder: string;
  defaultTerm?: string;
  selectedAddress: (address?: SelectedAddressType) => void;
  error?: boolean;
  onFocus?: () => void;
};

const PlacesAutocomplete = (
  { placeholder, defaultTerm, selectedAddress, error, onFocus }: PlaceAutocompleteProps,
  forwardRef: any
) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "AU",
    },
    debounce: 300,
  });

  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (defaultTerm) {
      setValue(defaultTerm);
    }
  }, []);

  const ref = useOnclickOutside(
    () => {
      setHasFocus(false);
    },
    { disabled: !hasFocus || data.length === 0 }
  );

  const handleInput = (e: any) => {
    selectedAddress(undefined);
    setValue(e.target.value);
  };

  const hasAustralianPostalCode = (address: string) => {
    // Regular expression pattern to match 4 or 5-digit Australian postal codes
    const postalCodePattern = /\b\d{4}(?:\d{1})?\b/;

    // Use the test() method to check if the address contains a valid postal code
    return postalCodePattern.test(address);
  };

  const handleSelect = (selection: Suggestion) => () => {
    // console.log("selection", selection);
    clearSuggestions();

    // Replace the keyword without request data from API
    setValue(selection.description, false);

    // try to get details for the selected place
    getDetails({placeId: selection.place_id})
      .then((details: any) => {
        // console.log("details: ", details);

        if (typeof details === "string") {
          throw(new Error(details));
        }

        const full = details.formatted_address ?? selection.description;
        const split = details.address_components
          ?.map((component: any) => component.short_name)
          ?? selection.terms;

        // update text box value
        setValue(full, false);

        // check validity & set selected address
        const types = details.types ?? selection.types;
        const isValidAddress = types.includes("premise")
                            || types.includes("subpremise")
                            || types.includes("street_address");
        const hasPostCode = hasAustralianPostalCode(full);
        selectedAddress({full, split, isValidAddress, hasPostCode});

      })
      .catch((e) => {
        // console.error(e);

        // fallback to simple address
        const isValidAddress =
          (selection.types.includes("premise") ||
            selection.types.includes("subpremise") ||
            selection.types.includes("street_address")) &&
          !selection.description.includes("?");
        const hasPostCode = hasAustralianPostalCode(selection.description);

        selectedAddress({
          full: selection.description,
          split: selection.terms,
          isValidAddress,
          hasPostCode,
        });
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <span>{secondary_text}</span>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <InputField
        ref={forwardRef}
        value={value}
        onChange={handleInput}
        onFocus={(e) => {
          onFocus?.();
          setHasFocus(true);
        }}
        disabled={!ready}
        placeholder={placeholder}
        error={!!error}
      />
      {status === "OK" && hasFocus && (
        <SuggestedPlacesList>{renderSuggestions()}</SuggestedPlacesList>
      )}
    </div>
  );
};
export default forwardRef(PlacesAutocomplete);
