import styled from "@emotion/styled";
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import MoodRoundedIcon from '@mui/icons-material/MoodRounded';
import Header from "components/header/Header";
import PhonePreview from "components/phone-preview/PhonePreview";

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  user-select: none;
  font-family: Montserrat, system-ui;

  //padding: 20px 0;
  box-sizing: border-box;
  background: radial-gradient(70% 100% at 90% 100%, rgba(209,208,198, 0.7) 0%, #FF000000 100%),radial-gradient(60% 70% at 0% 100%, rgba(255,255,254,1) 0%, #FF000000 100%),linear-gradient(270deg, rgba(210,210,203, 0.5) 0%, rgba(231,231,224, 0.5) 100%);
`;
const TitleText = styled.div`
  //max-width: 50%;
  margin-bottom: 0.5em;
  display: block;
  font-size: 1.8em;
  font-weight: 700;
  //letter-spacing: 0.05em;
`;
const DetailText = styled.div`
	//max-width: 50%;
  margin-bottom: 0.5rem;
  display: block;
  font-size: 0.9em;
  font-weight: 400;
	line-height: 1.8em;
  //letter-spacing: 0.05em;
`;


const PrimaryButton = styled.button`
  appearance: none;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: auto;
  height: 50px;
  border: none;
  font-family: Montserrat, system-ui;
  font-size: 1.2em;
  font-weight: 600;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  color: white;
  transition: all 0.1s ease-in-out;
  &:active {
    transform: scale(0.98);
    opacity: 0.5;
  }
`;
const SecondaryButton = styled.label`
  //appearance: none;
  border: none;
  padding: 1rem;
  font-family: Montserrat, system-ui, sans-serif;
  font-size: 1em;
  font-weight: 400;
  background: none;
  color: #2b2bff;
  &:active {
    opacity: 0.5;
  }
	&:focus-visible {
    outline: none;
    box-shadow: none;
    background: none;
  }
`;


const InstructionContainer = styled.div`
  //height: 50vh;
	width: 60%;
	position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  align-content: center;
	pointer-events: none;
`;

const SelfieOutlineBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  position: absolute;
  width: 60%;
  aspect-ratio: 1;

  --b: 5px; /* thickness of the border */
  --c: #4e4e4e; /* color of the border */
  --w: 30%; /* width of border */
  --r: 12px; /* radius */

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border: var(--c, red) var(--b) solid;
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b)/calc(100% - 2*var(--w)) 100% repeat-y,
    linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w)) repeat-x,
    linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0);
    -webkit-mask-composite: xor; //destination-out;
    mask-composite: exclude;
  }
`;

const SelfiePreview = () => {

	// return (
	// 	<div>
	// 		<MoodRoundedIcon style={{fontSize: 120}} fontSize={'inherit'}/>
	// 	</div>
	// )

	return (
		<PhonePreview>
			<SelfieOutlineBox>
				<MoodRoundedIcon style={{fontSize: 'calc(var(--root-view-width) * 0.22)'}} fontSize={'inherit'}/>
			</SelfieOutlineBox>
		</PhonePreview>
	)
}

export const SelfieInstructions = ({showCamera, selectedImage} : {showCamera: () => void, selectedImage: (image: File) => void}) => {

	const selectedGalleryImage = (e: any) => {
		const image  = e.target.files[0];
		// console.log(image);
		selectedImage(image);
	}

	const SelectGalleryButton = () => (
		<>
			<SecondaryButton htmlFor="upload-button">
				Upload from gallery instead
			</SecondaryButton>
			<input
				type="file"
				accept="image/*"
				id="upload-button"
				style={{ display: "none" }}
				onChange={selectedGalleryImage}
			/>
		</>
	)

	return (
		<Container>
			<Header style={{marginTop: -20}}/>
			<SelfiePreview />
			<div style={{ width: '100%', paddingBottom: '10%' }}>
				<TitleText>Selfie Time!</TitleText>
				<DetailText>Now, let's get a clear photo of your face.<br/>This will go on your member card.</DetailText>
				<PrimaryButton onClick={showCamera}>
					<CameraAltRoundedIcon />
					<span>Capture Selfie</span>
				</PrimaryButton>
				{ /* SelectGalleryButton() */ }
			</div>
		</Container>
	)
}
