import React from "react";
import styled from "@emotion/styled";
import HeaderBar from "components/header/Header";

type CoverBannerProps = {
	backgroundImage: string
}
const CoverBanner = styled.div<CoverBannerProps>`
  //height: 35vh;
	//height: calc(var(--root-view-width) / 1.5);
	width: 120%;
	//aspect-ratio: 2.2;
	flex-basis: 30%;
	flex-shrink: 0.4;
	//flex-grow: 0;
	
	display: flex;
  align-items: center;
	justify-content: center;
	border-radius: 0 0 50% 50%;
	margin-top: calc(var(--root-view-width) * -0.2);
  margin-bottom: calc(var(--root-view-width) * 0.12);
  position: relative;
  // flex-grow: 1;
  // max-height: 40vh;
	
	background-image: url(${props => props.backgroundImage});
  background-position: 50% 10%;
  background-size: 150%;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -1.5px; 
    border-radius: inherit;
    background: linear-gradient(90deg, rgba(180,148,69,1) 0%, rgba(199,184,82,1) 100%);
	  transform: scaleY(102.5%);
  }
`

const CircleLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  width: 22%;
	aspect-ratio: 1;
  position: absolute;
	overflow: hidden;
	bottom: 0;
  transform: translateY(50%);
	border-radius: 50%;
	border: rgb(186,159,73) 2.5px solid;
  background: radial-gradient(rgba(34,33,34,1) 10%, rgba(10,8,11,1) 100%);
	& > img {
    height: 70%;
		aspect-ratio: 1;
    object-fit: contain;
    //margin: 15%;
	}
`;

interface LandingBannerProps {
	style?: React.CSSProperties | undefined
	coverBackgroundImgUrl?: string
	logoImageUrl?: string
}

const LandingBanner = ({ style, coverBackgroundImgUrl, logoImageUrl }: LandingBannerProps) => {
	return (
		<CoverBanner backgroundImage={coverBackgroundImgUrl ?? ""} style={style}>
			{/*<HeaderBar showShadow />*/}
			<CircleLogo>
				<img src={logoImageUrl}/>
			</CircleLogo>
		</CoverBanner>
	)
}
export default LandingBanner;
