import styled from "@emotion/styled";
import React from "react";


const InstructionContainer = styled.div`
  //height: 50vh;
  --phone-container-width: calc(var(--root-view-width) * 0.5);
	width: var(--phone-container-width);
	position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  align-content: center;
	pointer-events: none;
`;
const PhoneBackground = styled.div`
	--phone-radius: calc(var(--phone-container-width) * 0.14);
	position: absolute;
  background: white;
  mask-image: linear-gradient(to top, transparent 0%, black 25%);
  border-radius: var(--phone-radius) var(--phone-radius) 0 0;
  width: calc(100% - 2px);
  aspect-ratio: 0.75;
`;
const PhoneOutline = styled.img`
  position: relative;
  width: 100%;
  aspect-ratio: 0.75;
  object-fit: cover;
  object-position: top;
  mask-image: linear-gradient(to top, transparent 0%, black 25%);
`;

interface PhonePreviewProps {
	style?: React.CSSProperties | undefined
	children?: React.ReactNode
}

const PhonePreview = ({children}: PhonePreviewProps) => {

	return (
		<InstructionContainer>
			<PhoneBackground />
			{children}
			<PhoneOutline src={`../assets/images/phone-frame.svg`} />
		</InstructionContainer>
	)
}
export default PhonePreview;
