import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { useMembershipApplication } from 'context/ApplicationContext';
import ApiClient from 'rest/ApiClient';
// import DebounceButton from "components/debounce-button/DebounceButton";
import LandingBanner from 'components/landing-banner/LandingBanner';
import { Button, TextField } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

type ContainerProps = {
  backgroundImage?: string;
};
const Container = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  user-select: none;
  //padding: 20px 0;
  box-sizing: border-box;
  //clip-path: path('M 0 200 L 0,75 A 5,5 0,0,1 150,75 L 200 200 z');
  background: radial-gradient(
      70% 40% at 110% 100%,
      rgba(209, 208, 198, 0.7) 0%,
      #ff000000 100%
    ),
    radial-gradient(
      60% 70% at 0% 100%,
      rgba(255, 255, 254, 1) 0%,
      #ff000000 100%
    ),
    linear-gradient(
      270deg,
      rgba(210, 210, 203, 0.5) 0%,
      rgba(231, 231, 224, 0.5) 100%
    );

  font-size: calc(8px + var(--root-view-width) * 0.017);
  font-family: Montserrat, sans-serif;

  ${(props) => {
    if (props.backgroundImage) {
      return `
				background: url(${props.backgroundImage}) center;
	      background-size: cover;
	      
	    `;
    }
    return null;
  }};
`;

const TitleText = styled.div`
  max-width: 75%;
  //margin-bottom: 0.5rem;
  display: block;
  font-size: 1.7em;
  font-weight: bold;
  //background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  background: linear-gradient(
    90deg,
    rgba(178, 144, 68, 1) 0%,
    rgba(199, 185, 82, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.02em;
`;
const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //justify-content: space-evenly;
  justify-content: start;
  flex-grow: 1;
  //margin: 4% 0;
  margin-top: 4%;
  align-items: center;
`;
const DetailsContainer = styled.div`
  width: 90%;
  padding: 0.5em 0;
  font-size: 0.9em;
  font-weight: 500;
  & ul,
  ol {
    list-style-position: inside;
    text-align: left;
    //line-height: 1.8em;
    //margin-block-start: 0;
    //padding-inline-start: 0;
    padding-inline: 1em;
    & li {
      padding: 0.5em 0;
    }
  }
`;
// const PrimaryButton = styled(DebounceButton)`
const PrimaryButton = styled.button`
  appearance: none;
  width: 85%;
  //margin-top: 0.5em;
  margin-top: auto;
  height: 50px;
  border: none;
  font-family: Montserrat, system-ui, sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  border-radius: 25px;
  background: linear-gradient(
    90deg,
    rgba(173, 134, 65, 1) 0%,
    rgba(198, 185, 81, 1) 100%
  );
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  transition: all 0.1s ease-in-out;
  &:active {
    transform: scale(0.98);
    opacity: 0.5;
  }
`;

export const PoweredByLogo = styled.img`
  //width: 80px;
  height: 8vh;
  margin: 0 0 -1vh 0;
  //object-fit: contain;
`;

export const LandingPage = () => {
  let navigate = useNavigate();
  const { appState, setUserInfo } = useMembershipApplication();

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [phone, setPhone] = useState('+61');
  const [isValid, setIsValid] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    // reset user state
    setUserInfo({});
  }, [setUserInfo]);

  // 	const homeContent  = `
  // 		<p>The ${appState.theme?.displayName} Rewards allows you to accumulate Rewards Points by simply using your card within the Club.</p>
  // 			<ul>
  // 				<li>something</li>
  // 				<li>other thing</li>
  // 				<li>And so much more!</li>
  // 				<li>See our full range of benefits <a href={'https://www.google.com'}>here</a></li>
  // 			</ul>
  // 		<p>Sign up now to ${appState.theme?.displayName} Rewards and start receiving your benefits today!</p>
  // `;

  const getStartedTapped = () => {
    // navigate(`/${ApiClient.venueKey}/licence`, { replace: true });
    navigate(`/${ApiClient.venueKey}/licence`);
  };

  const handlePhoneChange = (value: string) => {
    setError(null);
    setSuccess(null);
    if (value.length > 12) return;

    // Remove all non-digit characters from the input
    const cleanedValue = value.replace(/\D/g, '');

    // Ensure the value starts with +61 and contains only numbers
    const isValidFormat =
      cleanedValue.startsWith('+61') && /^[0-9]+$/.test(cleanedValue);

    // If the cleaned value doesn't start with '+', prepend it
    const formattedValue = cleanedValue.startsWith('+')
      ? cleanedValue
      : `+${cleanedValue}`;

    if (isValidFormat || formattedValue === '' || formattedValue.length <= 12) {
      // Update the state only if the input matches the format or is empty and not longer than 12 characters
      setPhone(formattedValue);
      setIsValid(true); // Set isValid to true if the input is valid
    } else {
      setIsValid(false); // Set isValid to false if the input is invalid
    }
  };

  const handleSubmit = async () => {
    // Ensure the value starts with +61 and contains only numbers
    const isValidFormat = phone.startsWith('+61');

    if (!isValidFormat) {
      setError('Invalid phone number');
      setSuccess(null);
      return;
    }

    try {
      setSuccess(null);
      setError(null);
      console.log('sending sms');
      const res = await ApiClient.sendDownloadSms(phone);
      console.log(res);

      if (res.data.success) {
        setSuccess(`An SMS with a download Link has been sent to ${phone}`);
        return;
      }
      if (!res.data.success) {
        setError(res.data.errorMessage);
        return;
      }
    } catch (e) {
      console.log(e);
      setError('Failed to send sms');
      setSuccess(null);
      return;
    }
  };

  const reset = () => {
    setError(null);
    setSuccess(null);
    setIsValid(false);
    setPhone('+61');
  };

  return (
    <Container /* backgroundImage={appState.theme?.coverBackgroundImgUrl} */>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form
            className="text-center max-w-sm mx-auto"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="mb-5">
              {success ? (
                <div>
                  <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <label
                    htmlFor="phone"
                    className="mt-4 block mb-2 text-lg font-medium text-green-900 dark:text-white"
                  >
                    Success!
                  </label>
                </div>
              ) : error ? (
                <div>
                  <div className="w-12 h-12 rounded-full bg-red-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18 17.94 6M18 18 6.06 6"
                      />
                    </svg>
                  </div>
                  <label
                    htmlFor="phone"
                    className="mt-4 block mb-2 text-lg font-medium text-red-900 dark:text-white"
                  >
                    Error!
                  </label>
                </div>
              ) : (
                <div>
                  <label
                    htmlFor="phone"
                    className="mt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter Member's Phone Number
                  </label>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Only existing or previous Members are supported
                  </p>
                </div>
              )}

              {success ? (
                <p className="text-green-500 text-sm mt-1">{success}</p>
              ) : error ? null : (
                <input
                  type="tel"
                  id="phone"
                  className="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="phone number here"
                  required
                  value={phone}
                  onChange={(e) => {
                    handlePhoneChange(e.target.value);
                  }}
                />
              )}
              {isValid ? null : (
                <p className="text-red-500 text-sm mt-1">
                  Invalid phone number
                </p>
              )}
              {error && (
                <Fragment>
                  <p className="text-red-500 text-md mt-1">
                    Membership not found for {phone}
                  </p>
                  <p className="text-black text-sm mt-1">
                    You will need to sign up using Hydra to get a Digital
                    Membership Card
                  </p>
                </Fragment>
              )}
            </div>

            {success || error ? (
              <button
                onClick={() => {
                  handleClose();
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Close
              </button>
            ) : (
              <button
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            )}
          </form>
        </Box>
      </Modal>
      <LandingBanner
        coverBackgroundImgUrl={appState.theme?.coverBackgroundImgUrl}
        logoImageUrl={appState.theme?.logoImgUrl}
      />
      <TextContentContainer>
        <TitleText>{appState.theme?.displayName} Rewards</TitleText>
        {/* show benefits of membership before showing onboarding the licence capture step */}
        {/* TODO: change to setHTML when enough browser coverage - or sanitise manually */}
        {/* setHTML - https://developer.mozilla.org/en-US/docs/Web/API/Element/setHTML */}
        <DetailsContainer
          dangerouslySetInnerHTML={{
            __html: appState.theme?.homePageRichHtml ?? '',
          }}
        />

        <PrimaryButton onClick={getStartedTapped}>Get Started</PrimaryButton>

        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        >
          <p className="mb-6 mt-4 font-light text-sm text-gray-500 hover:text-gray-900 dark:text-gray-400">
            Already signed Up? Click to Download your card
          </p>
        </a>
      </TextContentContainer>
      {/*<div>Powered by Player Elite</div>*/}
      <PoweredByLogo src={`../assets/images/poweredby-gold.png`} />
    </Container>
  );
};
