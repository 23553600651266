import React, {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from "react";
import styled from "@emotion/styled";

type BarProps = {
	color?: string,
	percent?: number,
	animDurationMs?: number
}
const Bar = styled.div<BarProps>`
	position: absolute;
	${(props) => {
		let width = props.percent ?? 100;
		let color = props.color ?? "black";
		let animDurationMs = props.animDurationMs ?? 300;
    return `
	    width: ${width}%;
	    background: ${color};
	    transition: all ${animDurationMs}ms ease-in-out;
    `;
  }}
  height: 6px;
	border-radius: 3px;
`;

interface LoadingBarProps {
	style?: React.CSSProperties | undefined,
	creepFromStart?: boolean,
}

export interface LoadingBarInterface {
	updatePercent: (percent: number, animated?: boolean) => void,
	rushComplete: () => Promise<void>
}

const LoadingBar = ({ style, creepFromStart }: LoadingBarProps, ref: Ref<LoadingBarInterface>,) => {
	const [percentFilled, setPercentFilled] = useState<number>(0);
	const [animDurationMs, setAnimDurationMs] = useState(300);
	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const creepProgress = (percent: number) => {
		const remainingProgress = 100 - percent;
		const increment = Math.round(remainingProgress / 10);
		const newPercent = Math.min(100, percent + increment);
		setPercentFilled(newPercent);

		if (newPercent < 100) {
			timerRef.current = setTimeout(() => {
				creepProgress(newPercent);
			}, animDurationMs);
		}
	}

	const rushComplete = () => {
		// console.log(`rushComplete: ${percentFilled}`);
		if (timerRef.current) {
			clearTimeout(timerRef.current);
			timerRef.current = null;
		}

		const completionTimeMs = 200;
		setAnimDurationMs(completionTimeMs);
		setPercentFilled(100);
		return new Promise<void>((resolve) => {
			setTimeout(() => {
				resolve();
			}, completionTimeMs);
		});
	}


	useImperativeHandle(ref, () => ({
		updatePercent: (percent: number, animated?: boolean) => {
			// console.log('loading bar update percent', percent);
			percent = Math.min(Math.max(percent, 0), 100);
      percent = Math.round(percent * 100) / 100;

			const uploadingPortion = 0.3;
			percent *= uploadingPortion;
			setPercentFilled(percent);

      if (percent === 100 * uploadingPortion) {
				// uploading done, steadily increase to give the illusion of consistent progress
	      setTimeout(() => {
		      creepProgress(percent)
	      }, animDurationMs);
      }
    },
		rushComplete
	}));

	useEffect(() => {
		if (creepFromStart) {
			creepProgress(0);
		}
	}, [])

	let backColour = "rgb(39,39,38)";
	let frontColour = 'linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%)';// "rgb(255,6,8)";

	return (
		<div style={{width: '100%', position: 'relative'}}>
			<Bar color={backColour} />
			<Bar color={frontColour} percent={percentFilled} animDurationMs={animDurationMs} />
		</div>
	)

}
export default forwardRef(LoadingBar)
