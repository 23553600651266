import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ApiClient from "rest/ApiClient";
import {isAndroid, isIos} from "util/environment";
import {useMembershipApplication} from "context/ApplicationContext";


export const CardDownloadPage = () => {
	const params = useParams();
	const [error, setError] = useState("");
	const { appState } = useMembershipApplication();

	const downloadCard = (phoneOs: "APPLE" | "GOOGLE") => {
		if (phoneOs !== "APPLE" && phoneOs !== "GOOGLE") {
			setError("invalid platform");
			return;
		}

		const token = params['token'];
		if (!token) {
			setError("invalid token")
			return;
		}

		const venueKey = params['venueKey']
		if (!venueKey) {
			setError("invalid url")
			return
		}
		ApiClient.venueKey = venueKey;

		// console.log(`APP - useEffect - ${ApiClient.venueKey}`);
		ApiClient.downloadCard(phoneOs, token)
			.then(response => {
				if (phoneOs === "APPLE") {
					// Convert the array buffer to a string to check for errors
					const responseText = new TextDecoder().decode(response.data);

					// Attempt to parse the response text as JSON
					let jsonResponse;
					try {
						jsonResponse = JSON.parse(responseText);
					} catch (e) {
						jsonResponse = null;
					}

					// Check if the response indicates an error
					if (jsonResponse && jsonResponse.success === false) {
						setError('failed to download the pass: ' + (jsonResponse.errorMessage || 'Unknown error'))
						return;
					}

					// Convert the array buffer to a blob and open the download link
					const blob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });
					window.location.href = window.URL.createObjectURL(blob);
				} else {
					const { cardUrl, success, errorMessage } = response.data;
					if (!cardUrl || success === false) {
						setError('failed to download the pass: ' + (errorMessage || 'Unknown error'))
						return;
					}

					// note: band-aid timeout fix for not opening url on ios
					setTimeout(() => window.open(cardUrl,'_blank'));
				}
			})
			.catch(error => {
				console.error('Error downloading the pass', error);
			});
	}

	useEffect(() => {
		const platform = isIos ? "APPLE" : (isAndroid && "GOOGLE") || "NA";
		if (platform === "NA") return;
		downloadCard(platform);
	}, [])

	if (error) {
		return (
      <div>
        Error: {error}
      </div>
    )
	}

	return (
		<div className='bg-slate-100 p-8 tall:h-dvh tall:pt-[60%] wide:rounded-xl max-h flex flex-col gap-4 items-center text-center m-auto dark:bg-slate-800'>
			{ appState.theme?.logoImgUrl && (
				<img src={appState.theme?.logoImgUrl} alt='logo' className='max-w-14 aspect-square object-contain'/>
			)}
			<h1 className='text-xl font-semibold'>Download your Membership Card</h1>
			<p className='font-medium'>Please select your preferred platform to download your membership card.</p>
			<div className='flex justify-center gap-4'>
				<button style={{width: '40%', appearance: 'none', background: 'none', border: 'none'}} onClick={() => {
					downloadCard("APPLE")
				}}>
					<img style={{width: '100%'}} src={'../../assets/images/add-to-apple-wallet-logo.png'}/>
				</button>
				<button style={{width: '40%', appearance: 'none', background: 'none', border: 'none'}} onClick={() => {
					downloadCard("GOOGLE")
				}}>
					{/*<img style={{width: '100%'}} src={'https://codelabs.developers.google.com/static/add-to-wallet-android/img/add-wallet-btn-large.png'}/>*/}
					<img style={{width: '100%'}} src={'../../assets/images/google-wallet-condensed.png'}/>
				</button>
				{/*<button>print card at kiosk</button>*/}
			</div>
		</div>
	)
}
export default CardDownloadPage;
