export function getScrollMidY(item: HTMLDivElement, parent: HTMLDivElement) {
	const itemTop = item.offsetTop;
	const itemHeight = item.clientHeight;
	const scrollViewHeight = parent.clientHeight;
	const halfScrollViewHeight = scrollViewHeight / 2;
	const scrollToY = itemTop - halfScrollViewHeight + itemHeight / 2;

	// Check if the item is at an edge of the list and adjust the scroll position accordingly
	const minScroll = 0;
	const maxScroll = parent.scrollHeight - scrollViewHeight;
	return Math.min(Math.max(scrollToY, minScroll), maxScroll);
}
export function animateScroll(element: HTMLDivElement, targetY: number, duration: number) {
	const startY = element.scrollTop;
	const change = targetY - startY;
	const startTime = performance.now();

	function animateScrollStep(currentTime: number) {
		const elapsed = currentTime - startTime;
		const progress = Math.min(elapsed / duration, 1);
		const easeProgress = progress < 0.5 ? 2 * progress * progress : (4 - 2 * progress) * progress - 1;

		element.scrollTop = startY + change * easeProgress;

		if (progress < 1) {
			requestAnimationFrame(animateScrollStep);
		}
	}

	requestAnimationFrame(animateScrollStep);
}
