import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styled from "@emotion/styled";
import Camera, { CameraInterface } from "../../components/camera/Camera";
import { useNavigate } from "react-router-dom";
import { LicenceInstructions } from "pages/camera/LicenceInstructions";
import CameraOverlay from "components/camera/CameraOverlay";
import { SelfieInstructions } from "pages/camera/SelfieInstructions";
import { FACING_MODES } from "jslib-html5-camera-photo";
import ApiClient, { LicenceType } from "rest/ApiClient";
import { uploadLicence, verifySelfie } from "rest/imageUploadFlow";
import { useMembershipApplication } from "context/ApplicationContext";
import { cropSelfieImage, cropLicenceImage } from "util/cropImage";
import Base64toBlob from "util/base64toBlob";
import CameraControls from "components/camera/CameraControls";
import {
  LoadingBarInterface,
} from "components/loading-bar/LoadingBar";
import PermissionsInstructions from "pages/camera/PermissionsInstructions";
import useBeforeUnload from "util/useBeforeUnload";
import ModalContents from "./ModalContents";

const CameraContainer = styled.div`
  position: relative;
  background: #111111;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  align-content: center;
`;

const ControlContainer = styled.div`
  --control-radius: calc(var(--root-view-width) * 0.07);

  display: flex;
  width: 100%;
  flex-grow: 1;
  background: radial-gradient(rgb(39, 39, 38) 10%, rgb(8, 6, 8) 100%);
  border-radius: var(--control-radius) var(--control-radius) 0 0;
  margin-top: calc(var(--control-radius) * -1);
  box-shadow: #00000099 0 0 var(--control-radius);
  z-index: 1;
  justify-content: space-evenly;
  align-items: center;
`;

const InstructionsOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

Modal.setAppElement("#root");

export const CaptureLicencePage = () => {
  let navigate = useNavigate();
  const { userInfo, setUserInfo, appState, setAppState } =
    useMembershipApplication();
  const [licenceImageUri, setLicenceImageUri] = useState<string | undefined>(
    undefined
  );
  const [licenceS3Key, setLicenceS3Key] = useState<string | undefined>(
    undefined
  );
  const [selfieImageUri, setSelfieImageUri] = useState<string | undefined>(
    undefined
  );

  const cameraInterface = useRef<CameraInterface>(null);
  const [cameraPermissionState, setCameraPermissionState] =
    useState<PermissionState>("prompt");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [uploadingMessage, setUploadingMessage] = useState<undefined | string>(
    undefined
  );
  const uploadingBarRef = useRef<LoadingBarInterface>(null);
  const [showInstructions, setShowInstructions] = useState(true);
  const pollCheckInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const [licenceData, setLicenceData] = useState<any>(undefined);
  const [selfieConfirmed, setSelfieConfirmed] = useState<any>(false);

  const [blockBack, setBlockBack] = useState(true);
  useBeforeUnload(blockBack && !!licenceS3Key);

  const clearPoll = () => {
    if (!pollCheckInterval.current) return;
    clearInterval(pollCheckInterval.current);
    pollCheckInterval.current = null;
  };

  useEffect(() => {
    return () => clearPoll();
  }, []);

  const showCamera = async () => {
    console.log("attempting to start camera");

    const facingMode = !licenceS3Key
      ? FACING_MODES.ENVIRONMENT
      : FACING_MODES.USER;
    const cameraStarted = await cameraInterface.current?.startCamera(
      facingMode
    );
    if (cameraStarted) {
      setShowInstructions(false);
    } else {
      console.log("camera failed to start");
    }
  };

  const hideCamera = () => {
    setShowInstructions(true);
    cameraInterface.current?.stopCamera();
  };

  const handleUploadLicence = async (
    imageName: string,
    imageData: any,
    licenceType: LicenceType
  ) => {
    setShowModal(true);

    const onProgress = (percent: number) => {
      console.log("upload progress: ", percent);
      uploadingBarRef.current?.updatePercent(percent);
      if (percent < 50) {
        setUploadingMessage("Uploading");
      } else if (percent < 100) {
        setUploadingMessage("Matching");
      } else {
        setUploadingMessage("Validating");
      }
    };
    onProgress(0);

    await uploadLicence(imageName, imageData, licenceType, onProgress)
      .then((data: any) => {
        console.log("success: ", data);

        if (!data.success) {
          setLicenceImageUri(undefined);
          setError(data.errorMessage ?? "Failed to detect licence in image");
          setTimeout(() => {
            setShowModal(false);
            setError(undefined);
          }, 2500);
          return;
        }

        const { driversLicenseDetails, flowToken, s3Key, isPensionEligible } =
          data;
        setAppState({ ...appState, flowToken });

        if (uploadingBarRef.current) {
          uploadingBarRef.current.rushComplete().then(() => {
            setUploadingMessage(undefined);
            setLicenceData({
              ...driversLicenseDetails,
              s3Key,
              isPensionEligible,
            });
          });
        } else {
          setLicenceData({
            ...driversLicenseDetails,
            s3Key,
            isPensionEligible,
          });
        }
      })
      .catch((error) => {
        console.log("err: ", error);
        setError("Request failed. Check Internet and try again.");
        setTimeout(() => {
          setShowModal(false);
          setError(undefined);
        }, 2500);
      });
  };

  const submitLicencePhoto = async () => {
    if (!licenceImageUri) return;
    const croppedLicence = await cropLicenceImage(licenceImageUri);
    Base64toBlob(croppedLicence, "licence").then(({ imageName, imageBlob }) =>
      handleUploadLicence(imageName, imageBlob, "CARD")
    );
  };

  const confirmedLicenceDetails = () => {
    setLicenceS3Key(licenceData.s3Key);
    setUserInfo({
      givenName: licenceData.firstName,
      familyName: licenceData.lastName,
      birthdate: licenceData.dateOfBirth,
      licenceAddress: licenceData.licenceAddress,
      isPensionEligible: licenceData.isPensionEligible,
    });

    setLicenceData(undefined);
    setShowModal(false);
    setShowInstructions(true);
    cameraInterface.current?.stopCamera();
  };

  const handleUploadSelfie = async (imageName: string, imageData: any) => {
    if (!licenceS3Key) return;
    setShowModal(true);

    const onProgress = (percent: number) => {
      console.log("upload progress: ", percent);
      uploadingBarRef.current?.updatePercent(percent);
      if (percent < 50) {
        setUploadingMessage("Uploading");
      } else if (percent < 100) {
        setUploadingMessage("Matching");
      } else {
        setUploadingMessage("Validating");
      }
    };
    onProgress(0);

    await verifySelfie(
      imageName,
      imageData,
      appState.flowToken ?? "",
      onProgress
    )
      .then((data: any) => {
        console.log("success: ", data);

        const { success, nextFlowGuid, gender, errorMessage } = data;
        if (!success) {
          if (errorMessage && errorMessage.length > 0) {
            setError(errorMessage);
          } else {
            setError("Selfie didn't match person in licence");
          }
          setTimeout(() => {
            setShowModal(false);
            setError(undefined);
            setSelfieImageUri(undefined);
          }, 2500);
          return;
        }

        cameraInterface.current?.stopCamera();

        setAppState({ ...appState, nextFlowGuid });
        setUserInfo({ ...userInfo, gender: (gender ?? "").toLowerCase() });
        setSelfieConfirmed(true);
        pollCheckInterval.current = setInterval(
          () => checkMembership(nextFlowGuid),
          1000
        );
      })
      .catch((error) => {
        console.log("err: ", error);
        setError("Request failed. Check Internet and try again.");
        setTimeout(() => {
          setShowModal(false);
          setError(undefined);
        }, 2500);
      });
  };
  const submitSelfiePhoto = async () => {
    if (!selfieImageUri) return;
    Base64toBlob(selfieImageUri, "selfie").then(({ imageName, imageBlob }) =>
      handleUploadSelfie(imageName, imageBlob)
    );
  };

  const checkMembership = async (nextFlowGuid: string) => {
    if (!appState.flowToken || !nextFlowGuid) return;

    const response = await ApiClient.isUserMember(
      nextFlowGuid,
      appState.flowToken
    );
    if (!response) return;
    const {
      data: { success, body },
    } = response;
    if (!success) return;
    clearPoll();

    if (uploadingBarRef.current) {
      await uploadingBarRef.current.rushComplete();
      setUploadingMessage(undefined);
    }

    setBlockBack(false);

    setTimeout(() => {
      setUserInfo({ ...userInfo, currentMemberInfo: body });

      if (body.isUserAMember) {
      } else {
        navigate(`/${ApiClient.venueKey}/form`, { replace: true });
      }
    }, 1000);
  };

  const CaptureControls = () => {
    const haveImage = !licenceS3Key ? !!licenceImageUri : !!selfieImageUri;
    const submitFunction = !licenceS3Key
      ? submitLicencePhoto
      : submitSelfiePhoto;
    const setImage = !licenceS3Key
      ? setLicenceImageUri
      : async (imageUri: string | undefined) => {
          if (!imageUri) {
            setSelfieImageUri(undefined);
            return;
          }

          const cameraContainerEl = document.querySelector("#camera-container");
          const croppedImage = await cropSelfieImage(
            imageUri,
            cameraContainerEl!.getBoundingClientRect()
          );
          if (!croppedImage) return;
          setSelfieImageUri(croppedImage);
        };

    return (
      <CameraControls
        haveImage={haveImage}
        onCapture={() => setImage(cameraInterface.current?.captureImage())}
        onConfirm={submitFunction}
        onRetake={() => setImage(undefined)}
        onBack={hideCamera}
      />
    );
  };

  const PreviewImage = () => {
    const imageUri = !licenceS3Key ? licenceImageUri : selfieImageUri;
    const style = !licenceS3Key
      ? { width: "100%" }
      : {
          width: "75%",
          top: "calc((75vh - var(--root-view-width) * 0.75) * 0.417)",
          boxShadow: "black 0 0 20px",
        };
    if (!imageUri) {
      return null;
    }
    return (
      <img
        alt="preview"
        src={imageUri}
        style={{
          ...style,
          transform: licenceS3Key ? "scaleX(-1)" : "scaleX(1)",
          position: "absolute",
          outline: "none",
        }}
      />
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <CameraContainer id="camera-container">
          <Camera
            ref={cameraInterface}
            onPermissionStateChange={setCameraPermissionState}
          />
          {PreviewImage()}
          <CameraOverlay
            type={!licenceS3Key ? "LICENCE" : "SELFIE"}
            previewingImage={licenceImageUri !== undefined}
          />
        </CameraContainer>
        <ControlContainer>{CaptureControls()}</ControlContainer>
      </div>
      {showInstructions && (
        <InstructionsOverlay>
          {cameraPermissionState === "denied" ? (
            <PermissionsInstructions
              onBack={() => setCameraPermissionState("prompt")}
            />
          ) : !licenceS3Key ? (
            <LicenceInstructions
              showCamera={showCamera}
              selectedImage={(image: File, licenceType) =>
                handleUploadLicence(image.name, image, licenceType)
              }
            />
          ) : (
            <SelfieInstructions
              showCamera={showCamera}
              selectedImage={(image: File) =>
                handleUploadSelfie(image.name, image)
              }
            />
          )}
        </InstructionsOverlay>
      )}

      <ModalContents
        showModal={showModal}
        userInfo={userInfo}
        pollCheckInterval={pollCheckInterval}
        appState={appState}
        uploadingBarRef={uploadingBarRef}
        selfieConfirmed={selfieConfirmed}
        licenceData={licenceData}
        confirmedLicenceDetails={confirmedLicenceDetails}
        setLicenceData={setLicenceData}
        setLicenceImageUri={setLicenceImageUri}
        setShowModal={setShowModal}
        error={error}
        uploadingMessage={uploadingMessage}
      />
    </>
  );
};
