import PlacesAutocomplete, {SelectedAddressType} from "components/places-autocomplete/PlacesAutocomplete";
import useScript from "util/useScript";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  Container,
  MemberInfoForm,
  PageSubHeading,
  StyledPhoneInput,
  ErrorMessage,
  InputField,
  InputSelect,
  NextButton,
  FormLabel,
} from "./FormSegements.styled";
import { FormPageType } from "./types";

import {
  useMembershipApplication,
} from "context/ApplicationContext";

import ApiClient from "rest/ApiClient";
import {forwardRef, RefObject, useState} from "react";

const ContactInfoForm = forwardRef(({ pageOffset, onComplete }: FormPageType, ref) => {
  const { appState, userInfo, setUserInfo } = useMembershipApplication();
  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const emailRequired = !(appState.theme?.emailOptional ?? false);
  const apiKey = "AIzaSyBirvHRC_ovnM0E6bwn0JB9j592n8R1180";
  const scriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
  );
  const [processingRequest, setProcessingRequest] = useState(false);

  const onSubmit = async (data: any) => {
    if (processingRequest) return;
    setProcessingRequest(true);

    try {
      // Validate address information
      if (!data.address || !data.address.full) {
        console.error("Address information is incomplete");
        setProcessingRequest(false);
        return;
      }

      const addressFull = data.address.full;
      console.log(addressFull);

      const updatedInfo = { ...userInfo, ...data, address: addressFull };
      setUserInfo(updatedInfo);

      // Validate flowToken
      if (!appState.flowToken) {
        console.error("Flow token is missing");
        setProcessingRequest(false);
        return;
      }

      // Make the API call
      const response = await ApiClient.submitAdditionalInfo(
        {
          phoneNumber: data.phone,
          emailAddress: data.email,
          homeAddress: addressFull,
          gender: data.gender,
          nameTitle: data.title,
        },
        appState.flowToken
      );

      // Check if the response is successful
      if (!response || !response.data.success) {
        console.error(response?.data?.errorMessage ?? "Unknown error occurred");
        setProcessingRequest(false);
        return;
      }

      console.log("response: ", response);
    } catch (e) {
      console.error("submit failed: ", e);
      setProcessingRequest(false);
      return;
    }

    setTimeout(() => {
      setProcessingRequest(false);
    }, 2000);
    onComplete();
  };

  return (
    <Container pageOffset={pageOffset} ref={ref as RefObject<HTMLDivElement>}>
      <MemberInfoForm key="contact-info" onSubmit={handleSubmit(onSubmit)}>
        <PageSubHeading>
          Let's get started by filling
          <br />
          out the details below.
        </PageSubHeading>
        <FormLabel>
          <span>Phone Number</span>
          <Controller
            name="phone"
            defaultValue={userInfo.number}
            control={control}
            rules={{
              required: true,
              validate: isValidPhoneNumber
            }}
            render={({ field }) => (
              <StyledPhoneInput
                {...field}
                defaultCountry="AU"
                id="phone"
                autoComplete="phone"
                onFocus={() => clearErrors("phone")}
                error={!!errors.phone}
              />
            )}
          />
          {errors.phone && <ErrorMessage>Invalid Phone</ErrorMessage>}
        </FormLabel>

        <FormLabel>
          <span>Email Address</span>
          <InputField
            defaultValue={userInfo.email}
            onFocus={() => clearErrors("email")}
            placeholder=""
            type="email"
            autoComplete="email"
            {...register("email", {
              required: emailRequired,
              // pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            error={!!errors.email}
          />
          {errors.email && <ErrorMessage>Invalid Email Address</ErrorMessage>}
        </FormLabel>

        <FormLabel>
          <span>Residential Address</span>
          <Controller
            name="address"
            defaultValue={userInfo.licenceAddress}
            control={control}
            rules={{
              required: true,
              validate: (value: SelectedAddressType) => {
                if (!value?.isValidAddress) {
                  return "Invalid address";
                }
                if (!value?.hasPostCode) {
                  return "Please include a post code";
                }
                return true
              },
            }}
            render={({ field: { onChange, value, ref } }) => {
              if (scriptStatus !== "ready") {
                return <InputField placeholder="Select..." disabled />;
              }
              return (
                <PlacesAutocomplete
                  ref={ref}
                  placeholder="Select..."
                  defaultTerm={value}
                  // onFocus={() => clearErrors("address")}
                  selectedAddress={(address) => {
                    if (address?.isValidAddress && address?.hasPostCode) {
                      clearErrors("address");
                    }
                    onChange(address);
                  }}
                  error={!!errors.address}
                />
              );
            }}
          />
          {errors.address && (
            <ErrorMessage>
              {errors.address.message ? (errors.address.message as string) : "Please select address"}
            </ErrorMessage>
          )}
        </FormLabel>

        <div style={{
            alignSelf: 'start',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10
          }}>
          <FormLabel style={{width: 'auto'}}>
            <span>Gender</span>
            <InputSelect
              defaultValue={userInfo.gender ?? ""}
              autoComplete="sex"
              style={{ width: "auto" }}
              {...register("gender", { required: true })}
            >
              <option value="" disabled hidden>
                Select
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </InputSelect>
          </FormLabel>

          { appState.theme?.showNameTitle && (
            <FormLabel style={{width: 'auto'}}>
              <span>Title</span>
              <InputSelect
                defaultValue={userInfo.title ?? ""}
                autoComplete="title"
                style={{ width: "auto" }}
                {...register("title", { required: !!appState.theme?.showNameTitle })}
              >
                <option value="" disabled hidden>
                  Select
                </option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
              </InputSelect>
            </FormLabel>
          )}

        </div>

        <NextButton type="submit" value="Next" />
      </MemberInfoForm>
    </Container>
  );
});

export default ContactInfoForm;
