import React, { useState } from 'react';
import SwitchSelector from "react-switch-selector";
import styled from "@emotion/styled";

const SwitchOptionLabel = styled.span`
	font-size: 12px;
  font-weight: 600;
  //color: #666;
	color: inherit;
  //margin-left: 10px;
	padding: 8px 4px;
	//font-family: 'Montserrat', system-ui, sans-serif;
`;

const defaultOptions = [
	// {
	// 	label: <span>Foo</span>,
	// 	value: {
	// 		foo: true
	// 	},
	// 	selectedBackgroundColor: "#0097e6",
	// },
	{
		label: <SwitchOptionLabel>SMS</SwitchOptionLabel>,
		value: "SMS",
	},
	{
		label: <SwitchOptionLabel>Email</SwitchOptionLabel>,
		value: "EMAIL",
	},
	{
		label: <SwitchOptionLabel>Do not send</SwitchOptionLabel>,
		value: "NO_SEND",
		// fontColor: "red"
	}
];

const optionsWithPost = [
	{
		label: <SwitchOptionLabel>SMS</SwitchOptionLabel>,
		value: "SMS",
	},
	{
		label: <SwitchOptionLabel>Email</SwitchOptionLabel>,
		value: "EMAIL",
	},
	{
		label: <SwitchOptionLabel>Post</SwitchOptionLabel>,
		value: "POST",
	}
];
const SwitchContainer = styled.div`
	display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  align-items: start;
  width: 100%;
  //height: 40px;
	user-select: none;
	gap: 6px;
  -webkit-tap-highlight-color: transparent;
  //-webkit-highlight: none;
  //mso-highlight: transparent;
`;

export type MarketingOption = "SMS" | "EMAIL" | "NO_SEND" | "POST";
interface MarketingToggleProps {
	name: string;
	initialValue?: MarketingOption;
	onToggleChange: (selectedOption: MarketingOption) => void;
	showPostOption?: boolean;
	hideEmail?: boolean;
}

const MarketingToggle = ({name, initialValue = "SMS", onToggleChange, showPostOption, hideEmail }: MarketingToggleProps) => {
	let options = showPostOption? optionsWithPost : defaultOptions;
	if (hideEmail) {
		options = options.filter(option => option.value !== "EMAIL");
	}
	const initialSelectedIndex = options.findIndex(({value}) => value === initialValue) ?? 0;
	const title = {
		"general-marketing": "I prefer to receive general marketing",
		"gaming-material": "I prefer to receive gaming material",
		"annual-report": "I prefer to receive the clubs annual report"
	}[name];

	const onChange = (option: any) => {
		// console.log(option);
		onToggleChange(option);
	};

	return (
		<SwitchContainer>
			<span>{title}</span>
			<SwitchSelector
				name={name}
				onChange={onChange}
				options={options}
				// border={"2px solid rgba(0,0,0,0.1)"}
				wrapperBorderRadius={12}
				optionBorderRadius={12}
				// fontSize={14}
				selectionIndicatorMargin={2}
				initialSelectedIndex={initialSelectedIndex}
				// backgroundColor={"#353b48"}
				backgroundColor={"#ffffffaa"}
				selectedBackgroundColor={"linear-gradient(45deg, rgba(186,177,90,1) 0%, rgba(161,142,75,1) 100%)"}
				// fontColor="#fff"
				// selectedFontColor={"#f5f6fa"}
			/>
		</SwitchContainer>
	);
};

export default MarketingToggle;
