// , {x = 0, y = 0, width = 400, height = 300}
const cropSelfieImage = async (imgUri: string, containerRect: DOMRect): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log("Values in crop", x, y, width, height);
            let resize_canvas = document.createElement('canvas');
            let orig_src = new Image();
            orig_src.src = imgUri;
            orig_src.onload = function () {
                // console.log(orig_src.width, orig_src.height)
                console.log(`NAT width: ${orig_src.naturalWidth}, height: ${orig_src.naturalHeight}`);
                //NAT width: 2160, height: 3088

                console.log(containerRect);
                // const viewHeight = containerRect.height;
                // const boxSize = containerRect.width * 0.75;
                // const boxTop = (viewHeight - boxSize) * 0.417;


                const imageWidth = orig_src.naturalWidth;
                const imageHeight = orig_src.naturalHeight;
                const size = imageWidth * 0.75;
                const left = imageWidth * 0.125;

                const aspectRatio = containerRect.height / containerRect.width;
                const adjustedHeight = imageWidth * aspectRatio
                const adjustedTop = (imageHeight - adjustedHeight) / 2;
                const boxTop = adjustedTop + ((adjustedHeight - size) * 0.417);

                // const centerY =
                // const top = (orig_src.naturalHeight - size) / 2;
                resize_canvas.width = size;
                resize_canvas.height = size;

                let ctx = resize_canvas.getContext('2d');
                if (!ctx) {
                    reject()
                    return;
                }
                // ctx.drawImage(orig_src, x, y, width, height, 0, 0, width, height);
                ctx.drawImage(orig_src, left, boxTop, size, size, 0, 0, size, size);
                let croppedImgUri = resize_canvas.toDataURL("image/jpg").toString();
                resolve(croppedImgUri);
            }
        }
        catch (e) {
            console.error("Couldn't crop image: ", e);
            reject(e);
        }
    })
}

const cropLicenceImage = async (imgUri: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log("Values in crop", x, y, width, height);
            let resize_canvas = document.createElement('canvas');
            let orig_src = new Image();
            orig_src.src = imgUri;
            orig_src.onload = function () {
                // console.log(orig_src.width, orig_src.height)
                // console.log(`NAT width: ${orig_src.naturalWidth}, height: ${orig_src.naturalHeight}`);

                const imageWidth = orig_src.naturalWidth;
                const imageHeight = orig_src.naturalHeight;
                const croppedWidth = imageWidth;
                const croppedHeight = imageWidth * 0.7;
                const top = (imageHeight - croppedHeight) * 0.46;

                // todo: set a max size instead of always scaling down to account for different cameras - eg 500px wide
                const scale = 1;
                resize_canvas.width = croppedWidth/scale;
                resize_canvas.height = croppedHeight/scale;

                let ctx = resize_canvas.getContext('2d');
                if (!ctx) {
                    reject()
                    return;
                }
                ctx.drawImage(orig_src, 0, top, croppedWidth, croppedHeight, 0, 0, resize_canvas.width, resize_canvas.height);
                let croppedImgUri = resize_canvas.toDataURL("image/jpg").toString();
                resolve(croppedImgUri);
            }
        }
        catch (e) {
            console.error("Couldn't crop image: ", e);
            reject(e);
        }
    })
}
export { cropSelfieImage, cropLicenceImage };
