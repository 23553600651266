import {useNavigate, useParams} from "react-router-dom";
import {useMembershipApplication} from "context/ApplicationContext";
import React, {useEffect, useState} from "react";
import ApiClient from "rest/ApiClient";
import OtpInput from 'react-otp-input';
import styled from "@emotion/styled";
import {PoweredByLogo} from "pages/landing/LandingPage";
import Header from "components/header/Header";
import {Circles} from "react-loader-spinner";
import FmdBadIcon from "@mui/icons-material/FmdBad";

const Container = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	text-align: center;
  user-select: none;
	font-family: Montserrat, system-ui;
	
  //padding: 20px 0;
  box-sizing: border-box;
  background: radial-gradient(70% 100% at 90% 100%, rgba(209,208,198, 0.7) 0%, #FF000000 100%),radial-gradient(60% 70% at 0% 100%, rgba(255,255,254,1) 0%, #FF000000 100%),linear-gradient(270deg, rgba(210,210,203, 0.5) 0%, rgba(231,231,224, 0.5) 100%);
`;

const OtpCard = styled.div`
  //min-width: 360px;
  //max-width: 70%;
  padding: 1rem 2rem 1.5rem 2rem;
	font-size: 14px;
	//box-sizing: border-box;
	//margin: 0 200px;
  box-shadow: 0 1px 3px #d4d4d5,0 0 0 1px #d4d4d5;
  border-radius: 16px;
  background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
  gap: 8px;
	
	& > p {
    //font-size: 1.5em;
    //font-weight: 700;
	}
	& input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
	& input {
    //-webkit-appearance: none;
    appearance: none;
		-moz-appearance: textfield;
    width: 1.5em !important;
    height: 2.5em;
		//aspect-ratio: 1 !important;
	//	overflow: hidden;
    //box-sizing: border-box;
    //display: block;
		
    margin: 0 0.3rem;
    font-size: 1em;
    font-weight: 700;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,.3);
	}
`
const ErrorLabel = styled.div`
  color: red;
  /* font-size: 1.5em; */
  font-weight: 500;
  text-align: center;
  position: absolute;
  width: 100%;
  line-height: 150%;
`

const ConfirmButton = styled.button`
  appearance: none;
  width: 90%;
  //margin-top: 20px;
  margin: 20px auto 0 auto;
  height: 44px;
  border: none;
  font-family: 'Montserrat', system-ui;
  font-size: 1em;
  font-weight: 600;
  border-radius: 22px;
  background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  filter: grayscale(0%);
  &:active:enabled {
    opacity: 0.5;
    transform: scale(0.98);
  }
  &:disabled {
    opacity: 0.5;
    filter: grayscale(100%);
  }
  transition: 0.2s ease-in-out;
`;

export const RestoreSessionPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const {appState, setAppState} = useMembershipApplication();
	const [error, setError] = useState<string | undefined>(undefined);

	const [restoreToken, setRestoreToken] = useState<string | undefined>(undefined);
	const [needValidation, setNeedValidation] = useState<boolean>(false); // false
	const [phoneNumberEnd, setPhoneNumberEnd] = useState<string>('***');
	const [otp, setOtp] = useState('');
	const [otpError, setOtpError] = useState('');


	const expireSession = async () => {
		if (!restoreToken) return;

		ApiClient.expireSession(restoreToken).then((response: any) => {
			console.log(response);
		})
	}

	const validateOtp = async () => {
		if (!restoreToken) return;
		if (otpError) return;

    const response = await ApiClient.vaidateRestoreOTP(restoreToken, otp);
    console.log(response);

		/*
		expired or already consumed
		{
	    "success": true,
	    "error": "CONSUMED" | "EXPIRED"
		}

		incorrect code:
		{
		    "success": false
		}
		*/

		if (response) {
			const { success, error } = response.data;
			if (!success) {
				setOtpError("INCORRECT CODE");
				return;
			}

			if (error) {
				if (error === "CONSUMED") {
					setOtpError("SMS CODE HAS BEEN USED");
				}
				if (error === "EXPIRED") {
					setOtpError("SMS CODE IS EXPIRED");
				}
				setOtpError(error);
			} else {
				await restoreSession();
			}
		}
	}
	const restoreSession = async () => {
		if (!restoreToken) return;

		ApiClient.restoreSession(restoreToken)
			.then((response: any) => {
				// console.log(response);

				/*
				{
				    "success": true,
				    "body": {
				        "isPaid": false,
				        "flowToken": "aa557d43799840ebb8546d442264f678c37e7ee5a46d44189ef593ba93c2c8743378f3",
				        "nextFlowGuid": "09244c41f0e04ec9b81ad89ffcececc9b653682548964e7996922faaee5470699cb7fc",
				        "venueMembershipProductId": 2
				    }
				}

				FAIL RESPONSE:
				{
				    "success": true,
				    "body": {
				        "isExpired": true,
				        "phoneNumberEnd": "319"
				    }
				}
				*/
				const { success, body } = response.data;
				if (!success) {
          setError("Invalid session token");
					return;
        }
				console.log(body);

				// setNeedValidation(false);
        setError(undefined);

				const { isPaid, flowToken, nextFlowGuid, venueMembershipProductId, isExpired, phoneNumberEnd } = body;
				if (isExpired) {
					setPhoneNumberEnd(phoneNumberEnd);
					setNeedValidation(true);
					return;
				}

				// todo: set data to appState? (flowToken, nextFlowGuid, selectedProductId)
				if (isPaid) {
					navigate(`/${ApiClient.venueKey}/complete?selectedId=${venueMembershipProductId}&flow=${nextFlowGuid}&token=${flowToken}`, { replace: true });
					return;
				}

				// use cash guid
				navigate(`/${ApiClient.venueKey}/complete?cashRequestGuid=${nextFlowGuid}&selectedId=${venueMembershipProductId}&flow=${nextFlowGuid}&token=${flowToken}`, { replace: true });

			})
			.catch((error) => {
				console.log(error.message);
				// did fail, show error with retry button
				// setError(error.message);
				setError("Network error, please try again later");

				return;
			})
	}


	useEffect(() => {
		const restoreToken = params['token']
		if (!restoreToken) {
			setError("invalid url")
			return
		}
		setRestoreToken(restoreToken);
		// ApiClient.venueKey = venueKey;

		console.log(`APP - useEffect - ${ApiClient.venueKey}`);

	}, [params])

	useEffect(() => {
		if (!restoreToken) return
		restoreSession();
	}, [restoreToken])

	if (error) {
		return (
			<div
				style={{
					fontSize: 32,
					textAlign: 'center',
					display: "flex",
					flexDirection: "column",
					gap: 20,
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "90%",
				}}
			>
				<FmdBadIcon style={{fontSize: 60}} fontSize={'inherit'} />
				<div style={{maxWidth: '70vw', textAlign: 'center'}}>{error}</div>
			</div>
		)
	}

	if (needValidation) {
		return (
      <Container>
	      <Header />
	      {/*<h1>Restore Session</h1>*/}
        <OtpCard>
	        <div>
		        <h2>Enter verification code</h2>
		        {/*<p>Your session expired. We sent you a verification code to pick up where you left off.</p>*/}
		        <p>Your session expired. We sent a verification<br/>code to your phone number ending in {phoneNumberEnd}<br/>so you can pick up where you left off.</p>
	        </div>
	        <div style={{position: "relative"}}>
		        <OtpInput
			        value={otp}
			        onChange={(value) => {
								setOtp(value);
								setOtpError('');
			        }}
			        numInputs={6}
			        inputType={'number'}
			        shouldAutoFocus
			        renderSeparator={<span>-</span>}
			        renderInput={(props) => <input {...props} />}
		        />
		        {otpError && <ErrorLabel>{otpError}</ErrorLabel>}
	        </div>
	        <ConfirmButton disabled={otp.length < 6 || !!otpError} onClick={validateOtp}>Confirm</ConfirmButton>
        </OtpCard>
	      <PoweredByLogo src={`../assets/images/poweredby-gold.png`} />
      </Container>
    )
	}

	return (
		<div
			style={{
				fontSize: 42,
				textAlign: 'center',
				display: "flex",
				flexDirection: "column",
				gap: 20,
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "90%",
			}}
		>
			<Circles
				height="60"
				width="60"
				color= "#2767ff" //"#4fa94d"
				ariaLabel="circles-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
			Restoring your session...
		</div>
	)

	// return (
	// 	<Container>
	// 		<Header />
	// 		{/*<h1>Restore Session</h1>*/}
	// 		{restoreToken && (
	// 			<div>
  //         <h2>Restore Token: {restoreToken}</h2>
	// 				<button onClick={restoreSession}>Restore Session</button>
	// 				<button onClick={expireSession}>EXPIRE Session</button>
  //       </div>
	// 		)}
	// 		<PoweredByLogo src={`../assets/images/poweredby-gold.png`} />
	// 	</Container>
	// )
}
