import styled from "@emotion/styled";

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: calc(var(--root-view-width) * 0.1);
	box-sizing: border-box;
`;

const BackButton = styled.button`
  appearance: none;
  margin: 30px auto;
  background: rgb(63,74,140);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 22px;
	font-family: Montserrat, system-ui;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
	gap: 4px;
	width: 90%;
	height: 44px;
  &:active {
    opacity: 0.5;
  }
`;


type PermissionProps = {
	onBack: () => void,
}
const PermissionsInstructions = ({onBack}:PermissionProps) => {
	//check if ios -> reload page and allow
	const isiOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
	if (isiOS) {
		return (
			<Container>
				<h1>Camera Permission Required</h1>
				<div>
          <p>
            <b>Please press back and allow access to your camera when you are prompted again.</b>
          </p>
          <p>
	          If you do not allow access to your camera, you will not be able to proceed through the application. <br/>
          </p>
        </div>
				<BackButton onClick={onBack}>Back</BackButton>
      </Container>
		)
	}


	// if chrome -> direct to top url bar
	const isChrome = navigator.userAgent.toLowerCase().includes("chrome");
	const isAndroid = navigator.userAgent.toLowerCase().includes("android");
	if (isChrome) {
		return (
			<Container>
				<h1>Camera Permission Required</h1>
				<div>
					<p>
						<b>Please confirm that you've given your web browser and this website permission to use the camera.</b>
					</p>
					<p>
						If you do not allow access to your camera, you will not be able to proceed through the application.
					</p>
					<p>
						To change the settings for this website:
						<ol>
							<li>Tap the lock icon to the left of the url</li>
							{isAndroid && (
								<li>Tap permissions</li>
							)}
							<li>Make sure the Camera toggle is on</li>
						</ol>
					</p>
				</div>
				<BackButton onClick={onBack}>Back</BackButton>
			</Container>
		)
	}

	// if android and not chrome -> direct to settings
	// if (isAndroid) {
	// 	return (
  //     <Container>
	//       <h1>Camera Permission Required</h1>
	//       <div>
  //         <p>
  //           <b>Please confirm that you've given your web browser and this website permission to use the camera.</b>
  //         </p>
  //         <p>
	//           If you do not allow access to your camera, you will not be able to proceed through the application.
  //         </p>
  //       </div>
	//       <BackButton onClick={onBack}>Back</BackButton>
  //     </Container>
  //   )
	// }

	return (
		<Container>
			<h1>Camera Permission Required</h1>
			<div>
				<p>
					<b>Please confirm that you've given your web browser and this website permission to use the camera.</b>
				</p>
				<p>
					If you do not allow access to your camera, you will not be able to proceed through the application.
				</p>
			</div>
			<BackButton onClick={onBack}>Back</BackButton>
		</Container>
	)
}
export default PermissionsInstructions;
